import React, { useState, useEffect } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import Contact from "../Contact/Contact";
import Modal from "react-bootstrap/Modal";

import { Helmet } from "react-helmet";

import "./branches.styles.scss";

import agsImgUrl from "../../assets/ags.jpg";
import chihImgUrl from "../../assets/img-chihuahua.jpg";
import clcImgUrl from "../../assets/img-culiacan-b.jpg";
import gdlImgUrl from "../../assets/img-guadalajara.jpg";
import iztaImgUrl from "../../assets/img-iztapalapa-b.jpg";
import leonImgUrl from "../../assets/img-leon.jpg";
import mazatImgUrl from "../../assets/img-mazatlan.jpg";
import meridaImgUrl from "../../assets/img-merida.jpg";
import montImgUrl from "../../assets/img-monterrey.jpg";
import moreImgUrl from "../../assets/img-morelia.jpg";
import pueImgUrl from "../../assets/img-puebla.jpg";
import quereImgUrl from "../../assets/img-queretaro-b.jpg";
import reynImgUrl from "../../assets/img-reynosa-2.jpg";
// import saltiImgUrl from "../../assets/img-saltillo.jpg";
import slpImgUrl from "../../assets/sanluis.jpg";
import tijImgUrl from "../../assets/img-tijuana.jpg";
import tlalnImgUrl from "../../assets/mexico.jpg";
import torrnImgUrl from "../../assets/img-torreon.jpg";
import vcrzImgUrl from "../../assets/img-veracruz.jpg";
import tmpSecondImgUrl from "../../assets/tampico-second.jpg";
import popUp from "../../assets/popUp.jpeg";

import { BranchCard } from "../CustomCards/CustomCards";

const branchesArray = [
  {
    imgUrl: agsImgUrl,
    cityName: "Aguascalientes",
    branchPhone: "4491464235",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm. ",
    branchLocation:
      "Carretera a San Luis Potosí km 2.0, Fracc. Casa Sólida, Aguascalientes, Aguascalientes. C.P. 20169",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox+Aguascalientes/@21.861506,-102.225083,17z/data=!4m5!3m4!1s0x0:0x387290c2ade98517!8m2!3d21.8615063!4d-102.2250835?hl=es-419",
  },
  {
    imgUrl: chihImgUrl,
    cityName: "Chihuahua",
    branchPhone: "6143070246",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. Alejandro Dumas No. 11130, No .Int 10 y 11, Complejo Industrial Chihuahua, Chihuahua, Chihuahua, C.P. 31136",
    branchLocationLink:
      "https://www.google.com/maps?ll=28.729956,-106.11388&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=2030636383931288465",
  },
  {
    imgUrl: clcImgUrl,
    cityName: "Culiacán",
    branchPhone: "6671705198",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Libramiento Sur Benito Juárez No. 6301, Int. 34ª Poniente, Col. Parque Industrial la costa, Culiacán Rosales, Culiacán, Sinaloa, C.P. 80140",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox/@24.748713,-107.457904,17z/data=!4m5!3m4!1s0x0:0xb43a5ee1ce281ebd!8m2!3d24.7487126!4d-107.457904?hl=es-419",
  },
  {
    imgUrl: gdlImgUrl,
    cityName: "Guadalajara",
    branchPhone: "3333444636",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. Patria No. 29, Col. El Manantial, Guadalajara, Jalisco, C.P. 44990",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.622249,-103.345902&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=17318830583385505820",
  },
  {
    imgUrl: iztaImgUrl,
    cityName: "Iztapalapa",
    branchPhone: "5555815896",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. 5 de Mayo No. 9, Local E, Colonia Ampliación los Reyes Culhuacán, Delegación Iztapalapa, C.P. 09849,Ciudad de México, México",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.343021,-99.105949&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=1610868234904062615",
  },
  {
    imgUrl: leonImgUrl,
    cityName: "León",
    branchPhone: "4777776405",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Blvd. Alonso de Torres, No. 231-A, Col. Las Hilamas, León, Guanajuato, C.P. 37353",
    branchLocationLink:
      "https://www.google.com/maps?ll=21.123541,-101.720131&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3260945479459544977",
  },
  {
    imgUrl: mazatImgUrl,
    cityName: "Mazatlán",
    branchPhone: "6691332595",
    branchSecondaryPhone: "6691332592",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Francisco Gonzalez Bocanegra No. 8900, Col. El Conchi II, Mazatlán, Sinaloa,C.P. 82134",
    branchLocationLink:
      "https://www.google.com/maps?ll=23.272148,-106.40246&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=12985841781860867956",
  },
  {
    imgUrl: meridaImgUrl,
    cityName: "Mérida",
    branchPhone: "9992522233",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Periférico poniente KM 41+700, No. 3, Fraccionamiento Juan Pablo II, Mérida, Yucatán, C.P. 97246",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.982791,-89.680539&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=14570237733386454061",
  },
  {
    imgUrl: montImgUrl,
    cityName: "Monterrey",
    branchPhone: "8181440010",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "América del Norte 208, Col. Parque Industrial Las Américas",
    branchLocationLink:
      "https://www.google.com/maps?ll=25.699747,-100.241753&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3198052743347169609",
  },
  {
    imgUrl: moreImgUrl,
    cityName: "Morelia",
    branchPhone: "4433230424",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "General Jose Aceves, No. 36, Col. Elías Pérez Avalos, Morelia, Michoacán de Ocampo,C.P. 58218",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.711324,-101.165418&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=5907667291632038982",
  },
  {
    imgUrl: pueImgUrl,
    cityName: "Puebla",
    branchPhone: "2222330000",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Calle: Río Lerma No. 10 Bodega 3, Colonia: San Lorenzo Almecatla, Cuautlancingo, Puebla, C.P.72730",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.118929,-98.257698&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=8138320865941269881",
  },
  {
    imgUrl: quereImgUrl,
    cityName: "Querétaro",
    branchPhone: "4422145914",
    branchSecondaryPhone: "4422175191",
    branchThirdPhone: "4422177766",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. Camino a Campo Militar No. 25 (KM 2.65), Nave A-4, Col. La Sierrita, Santiago de Querétaro, Querétaro, Querétaro, CP. 76137",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.594751,-100.418403&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=4052025410070731133",
  },
  {
    imgUrl: reynImgUrl,
    cityName: "Reynosa",
    branchPhone: "8999256505",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. Rodolfo Garza Cantú, No. 102, Col. Las Mitras, Reynosa, Tamaulipas, C.P. 88759",
    branchLocationLink:
      "https://www.google.com/maps?ll=26.04706,-98.300278&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15870023934054905851",
  },
  // {
  //   imgUrl: saltiImgUrl,
  //   cityName: "Saltillo",
  //   branchPhone: "8442779576",
  //   branchSchedule:
  //     "Lunes a Viernes 8:30 am a 6:00 pm.",
  //   branchLocation:
  //     "Blvd. Vito Alessio Robles 5625, Asturias. Saltillo, Coahuila, C.P. 25107",
  //   branchLocationLink:
  //     "https://www.google.com/maps/place/Prominox/@25.478969,-100.991305,17z/data=!4m5!3m4!1s0x0:0x5bcb75062832147a!8m2!3d25.4789685!4d-100.9913047?hl=es-419",
  // },
  {
    imgUrl: slpImgUrl,
    cityName: "San Luis Potosí",
    branchPhone: "4448161007",
    branchSecondaryPhone: "4448096454",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Carretera Rio Verde No. 2260, Col. Genovevo Rivas Guillen Gral.,Soledad de Graciano Sanchez, San Luis Potosí, C.P. 78436",
    branchLocationLink:
      "https://www.google.com/maps?ll=22.1533,-100.935828&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=10168027290383277582",
  },
  {
    imgUrl: reynImgUrl,
    cityName: "Tampico",
    branchPhone: "8332147349/50",
    branchSecondaryPhone: "8332193590/40",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "6A Avenida 317, Villa Hermosa, Tampico, Tamaulipas C.P. 89319 ",
    branchLocationLink:
      "https://www.google.com/maps?ll=22.323001,-97.86955&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15835830103075095847",
    secondImgUrl: tmpSecondImgUrl,
  },
  {
    imgUrl: tijImgUrl,
    cityName: "Tijuana",
    branchPhone: "6649695548",
    branchSecondaryPhone: "6649695576",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. Vía rápida oriente, No. 15540, Rio Tijuana 3ra Etapa, Tijuana, Baja California, C.P. 22226",
    branchLocationLink:
      "https://www.google.com/maps?ll=32.496169,-116.941583&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16608062537878815893",
  },
  {
    imgUrl: tlalnImgUrl,
    cityName: "Tlalnepantla",
    branchPhone: "5550050580",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm. ",
    branchLocation:
      "Henry Ford No. 4, No. Int. 3, Col. Industrial San Nicolas, Tlalnepantla, Edo de México, C.P. 54030",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox/@19.552802,-99.204649,17z/data=!4m5!3m4!1s0x0:0x3071f6b979be0655!8m2!3d19.5528017!4d-99.2046493?hl=es-419",
  },
  {
    imgUrl: torrnImgUrl,
    cityName: "Torreón",
    branchPhone: "8717187836",
    branchSecondaryPhone: "8717187293",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Blvd. Torreón Matamoros, No. 7691 Ote, No. Int. Manzana 57, Lote 8-C, Col. Oscar Flores Tapia, Torreón, Coahuila de Zaragoza, C.P. 27086",
    branchLocationLink:
      "https://www.google.com/maps?ll=25.535083,-103.34414&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16628314242009464813",
  },
  {
    imgUrl: vcrzImgUrl,
    cityName: "Veracruz",
    branchPhone: "2299208405",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm.",
    branchLocation:
      "Av. Sin nombre #94 A un costado de Velatorio la Luz Frente a la Laguna Malibrán, Floresta, 91940 Veracruz, Ver. C.P. 91940",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox+Veracruz+%7C+Acero+Inoxidable+%7C+Aluminio+%7C+Tuber%C3%ADa+Conduit/@19.1616498,-96.1385608,17z/data=!3m1!4b1!4m5!3m4!1s0x85c343522405ab89:0x87c23a38ef8c9132!8m2!3d19.1616633!4d-96.1363799",
  },
];

export default function Branches() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Sucursales</title>
        <meta
          name="description"
          content="Contamos con 20 sucursales a nivel nacional, una de ellas es  sucursal Aguascalientes donde se cuenta con inventario de producto y entrega just in time."
        />
        <meta
          name="keywords"
          content="sucursal aguascalientes, acero inoxidable, prominox, alumino, tubería conduit, accesorios para barandales, herrajes"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Sucursales" />
        <meta
          itemprop="description"
          content="Contamos con 20 sucursales a nivel nacional, una de ellas es  sucursal Aguascalientes donde se cuenta con inventario de producto y entrega just in time."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Sucursales" />
        <meta
          property="og:description"
          content="Contamos con 20 sucursales a nivel nacional, una de ellas es  sucursal Aguascalientes donde se cuenta con inventario de producto y entrega just in time."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Sucursales" />
        <meta
          name="twitter:description"
          content="Contamos con 20 sucursales a nivel nacional, una de ellas es  sucursal Aguascalientes donde se cuenta con inventario de producto y entrega just in time."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-branches">
          <Container>
            <h1 className="wow fadeInUp">sucursales</h1>
          </Container>
        </Jumbotron>
      </section>
      <section className="branches-container wow fadeInUp" data-wow-delay=".3s">
        <Container fluid>
          <CardDeck>
            {branchesArray.map(
              (
                {
                  imgUrl,
                  cityName,
                  branchPhone,
                  branchSecondaryPhone,
                  branchSchedule,
                  branchLocation,
                  branchLocationLink,
                  secondImgUrl,
                },
                key
              ) => (
                <BranchCard
                  key={key}
                  imgUrl={imgUrl}
                  cityName={cityName}
                  branchPhone={branchPhone}
                  branchSecondaryPhone={branchSecondaryPhone}
                  branchSchedule={branchSchedule}
                  branchLocation={branchLocation}
                  branchLocationLink={branchLocationLink}
                  secondImgUrl={secondImgUrl}
                />
              )
            )}
          </CardDeck>
        </Container>
      </section>
      <Contact />
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
