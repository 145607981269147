import React from "react";
import "./categories.styles.scss";

// Components
import { CategoryCard } from "../../components/Card";

// Dependencies
import Masonry from "react-masonry-css";

const breakpoints = {
  default: 2,
  991: 1,
};

const Categories = ({ categories }) => {
  // console.log(categories);
  return (
    <Masonry
      breakpointCols={breakpoints}
      className="category__item-container"
      columnClassName="category__item-container__column"
    >
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} />
      ))}
    </Masonry>
  );
};

export default Categories;
