import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

import "./jumbotron.styles.scss";

export const BlogJumbotron = () => {
  return (
    <Jumbotron fluid className="jumbotron-blog">
      <Container>
        <h1 className="wow fadeInUp">blog</h1>
        <h2 className="wow fadeInUp" data-wow-delay=".3s">
          prominox
        </h2>
      </Container>
    </Jumbotron>
  );
};

export const CategoryJumbotron = ({ title, bgImage }) => {
  const imageUrl = bgImage.url;
  return (
    <Jumbotron
      fluid
      className="jumbotron-category"
      style={{
        backgroundImage: `linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.73),
        rgba(0, 0, 0, 0.274)
      ), url(${imageUrl})`,
      }}
    >
      <Container>
        <h1 className="wow fadeInUp">{title}</h1>
      </Container>
    </Jumbotron>
  );
};
