import React from "react";
import { useParams } from "react-router";
import "./categories.styles.scss";

// Components
import { BlogJumbotron } from "../../components/Jumbotron/Jumbotron";
import CategoriesComponent from "../../components/Categories";
import Query from "../../components/Query";
import Contact from "../../components/Contact/Contact";

// Queries
import CATEGORIES_ARTICLES_QUERY from "../../queries/category/categories";

// Bootstrap
import Container from "react-bootstrap/Container";

const Categories = () => {
  let { id } = useParams();
  return (
    <Query query={CATEGORIES_ARTICLES_QUERY} id={id}>
      {({ data: { categories } }) => {
        return (
          <div className="articles-wrapper">
            <BlogJumbotron />
            <div className="articles">
              <div className="articles-container">
                <Container fluid>
                  <CategoriesComponent categories={categories} />
                </Container>
              </div>
            </div>
            <Contact />
          </div>
        );
      }}
    </Query>
  );
};

export default Categories;
