import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
// import Moment from "react-moment";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";

import { ArticleCard } from "../../components/Card";

import ARTICLE_QUERY from "../../queries/article/article";

import "./article.styles.scss";
import Contact from "../../components/Contact/Contact";

const getRelatedArticles = (arr, current) => {
  let relatedArticles = [];
  let relatedArticlesIds = [];
  let listArticles = [];

  relatedArticles = arr.filter((item) => item.id !== current);
  for (let i = 0; i < relatedArticles.length; i++) {
    const item = relatedArticles[i];
    relatedArticlesIds.push(item.id);
  }
  // console.log(relatedArticlesIds);
  // console.log(Math.floor(Math.random() * (arr.length - 1)) + 1);
  let items = [];
  for (let i = 0; i < 3; i++) {
    // const element = relatedArticles[i];
    let randomItem =
      relatedArticles[Math.floor(Math.random() * relatedArticlesIds.length)];
    if (items.includes(randomItem)) {
      // console.log("already");
      let newRandomItem =
        relatedArticles[Math.floor(Math.random() * relatedArticlesIds.length)];
      items.push(newRandomItem);
    } else {
      items.push(randomItem);
    }
  }
  // console.log(items);
  for (let i = 0; i <= items.length - 1; i++) {
    const item = items[i];
    // listArticles.push(<ArticleCard key={item.id} article={item} />);
    listArticles.push(item);
  }
  // console.log(listArticles);
  if (listArticles.length === 0) {
    return <p>No hay artículos relacionados.</p>;
  }
  return listArticles.map((article, id) => (
    <ArticleCard key={article.id} article={article} />
  ));
};

// const MyImage = (props) => {
//   return <img alt={props.alt} src={props.src} />;
// };

const Article = () => {
  let { id } = useParams();
  // console.log(id);
  // const renderers = {
  //This custom renderer changes how images are rendered
  //we use it to constrain the max width of an image to its container
  // image: ({ alt, src, title }) => (
  //   <div>
  //     <img alt={alt} src={src} title={title} style={{ maxWidth: 475 }} />
  //   </div>
  // ),
  // paragraph: ({ node, children }) => {
  //   console.log(children);
  //   const images = children[0];
  //   console.log(images);
  //   for (let index = 0; index < children[0].length; index++) {
  //     const element = children[index];
  //     if (element.type === "img") {
  //       return (
  //         <div
  //           style={{
  //             width: "100%",
  //             maxWidth: "60rem",
  //           }}
  //         >
  //           <img src={element.props.src} alt={element.props.alt} />
  //         </div>
  //       );
  //     } else return <p>{children}</p>;
  //   }
  // },
  // };

  return (
    <Query query={ARTICLE_QUERY} id={id}>
      {({ data: { articles } }) => {
        const imageUrl =
          process.env.NODE_ENV !== "development"
            ? articles[0].imagen.url
            : articles[0].imagen.url;
        console.log(articles);

        return (
          <div>
            <div
              id="banner"
              className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
              style={{
                backgroundImage: `linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.53),
                rgba(0, 0, 0, 0.2)
              ), url(${imageUrl})`,
              }}
              // data-srcset={imageUrl}
              // data-uk-img
            >
              <h1 className="wow fadeInUp">{articles[0].title}</h1>
            </div>
            <div>
              <div className="uk-section">
                <div className="uk-container uk-container-small">
                  {/* <h1>{article.title}</h1> */}
                  {/* <p>{article.content}</p> */}
                  <ReactMarkdown
                    source={articles[0].content}
                    // renderers={renderers}
                    escapeHtml={false}
                  />
                  {/* <p>
                  <Moment format="MMM Do YYYY">{article.published_at}</Moment>
                </p> */}
                </div>
                <div className="related-content">
                  <h3>Te podría interesar:</h3>
                  <Container fluid>
                    {articles[0].category.articles.length > 2 ? (
                      getRelatedArticles(articles[0].category.articles, id)
                    ) : (
                      <p>No hay artículos relacionados</p>
                    )}
                  </Container>
                </div>
              </div>
            </div>
            <Contact />
          </div>
        );
      }}
    </Query>
  );
};

export default Article;
