import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import { Helmet } from "react-helmet";

import "./steel.styles.scss";

import aceroIconUrl from "../../assets/img-home-acero.png";

import firstIconUrl from "../../assets/icn-01.svg";
// import firstIconUrl2 from "../../assets/icn-01.png";
import secondIconUrl from "../../assets/icn-02.svg";
// import secondIconUrl2 from "../../assets/icn-02.png";
import thirdIconUrl from "../../assets/icn-03.png";
import fourthIconUrl from "../../assets/icn-04.png";
import fifthIconUrl from "../../assets/icn-05.png";
import sixthIconUrl from "../../assets/icn-06.svg";
// import sixthIconUrl2 from "../../assets/icn-06.png";
import seventhIconUrl from "../../assets/icn-07.svg";
// import seventhIconUrl2 from "../../assets/icn-07.png";
import tuberiaPipeImgUrl from "../../assets/acero-inoxidable-tuberia-pipe.jpg";
import soleraImgUrl from "../../assets/acero-inoxidable-solera.jpg";
import rolloImgUrl from "../../assets/acero-inoxidable-rollo.jpg";
import placaImgUrl from "../../assets/acero-inoxidable-placa.jpg";
import ornamentalRedondaImgUrl from "../../assets/acero-inoxidable-ornamental-redonda.jpg";
import ornamentalRectangularImgUrl from "../../assets/acero-inoxidable-ornamental-rectangular.jpg";
import ornamentalCuadradaImgUrl from "../../assets/acero-inoxidable-ornamental-cuadrada.jpg";
import laminaImgUrl from "../../assets/acero-inoxidable-lamina.jpg";
import flejeImgUrl from "../../assets/acero-inoxidable-fleje.jpg";
import discoImgUrl from "../../assets/acero-inoxidable-disco.jpg";
import blankImgUrl from "../../assets/acero-inoxidable-blank.jpg";
import barraRedondaImgUrl from "../../assets/acero-inoxidable-barra-redonda.jpg";
import barraCuadradaImgUrl from "../../assets/acero-inoxidable-barra-cuadrada.jpg";
import antiderrapanteImgUrl from "../../assets/acero-inoxidable-antiderrapante.jpg";
import anguloImgUrl from "../../assets/acero-inoxidable-angulo.jpg";
import popUp from "../../assets/popUp.jpeg";
// import firstSlideImgUrl from "../../assets/accesorios-barandales-slide-1.png";
// import secondSlideImgUrl from "../../assets/accesorios-barandales-slide-2.png";
// import thirdSlideImgUrl from "../../assets/accesorios-barandales-slide-3.png";

import Contact from "../Contact/Contact";

import SteelVideo from "../../assets/videos/ACERO-INOX.mp4";

export default function Steel() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Acero inoxidable</title>
        <meta
          name="description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta
          name="keywords"
          content="acero inoxidable, rollo, placa, lámina, blank, perfiles, tubería, 304, 314, 20 sucursales"
        />
        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Acero inoxidable" />
        <meta
          itemprop="description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Acero inoxidable" />
        <meta
          property="og:description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Acero inoxidable" />
        <meta
          name="twitter:description"
          content="Contamos con aleaciones comerciales como 304, 316, 310, 430, 441, en presentaciones como rollo, lámina, placa, fleje, blank, tubería, perfiles de acero inoxidable."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-steel">
          <video autoPlay loop muted className="video d-none d-lg-block">
            <source src={SteelVideo} type="video/mp4" />
          </video>
          <Container>
            <h1 className="wow fadeInUp">acero inoxidable</h1>
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".7s"
            >
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Resistencia a la corrosión</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Durabilidad</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Resistencia mecánica</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Apariencia higiénica</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Excelente soldabilidad</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aceroIconUrl} alt="" />
                  <p>Resistencia a temperaturas extremas</p>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      <section className="carousel--steel-container mt-n5">
        <Carousel interval={null}>
          <Carousel.Item style={{ height: "100%" }}>
            <div className="carousel--steel-item carousel--steel-item--1">
              <div className="carousel--steel-item__heading">
                <h2 className="wow fadeInUp">ferríticos</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="wow fadeInUp" data-wow-delay=".3s">
                  <h4>409L</h4>
                  <h4>430</h4>
                  <h4>441</h4>
                </div>
              </div>
              <div
                className="carousel--steel-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={thirdIconUrl} alt="PROMINOX" />
                      <p>Magnéticos</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={secondIconUrl} alt="PROMINOX" />
                      <p>Buena formabilidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Difusión eficiente de calor</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--2">
            <div className="carousel--steel-item carousel--steel-item--2">
              <div className="carousel--steel-item__heading">
                <h2 className="wow fadeInUp">austeníticos</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="wow fadeInUp" data-wow-delay=".3s">
                  <h4>201</h4>
                  <h4>301</h4>
                  <h4>304</h4>
                  <h4>310</h4>
                  <h4>316</h4>
                </div>
              </div>
              <div
                className="carousel--steel-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={sixthIconUrl} alt="PROMINOX" />
                      <p>Excelente soldabilidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={fifthIconUrl} alt="PROMINOX" />
                      <p>Gran higiene y limpieza</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={3}>
                    <div className="carousel--steel-item__properties__card">
                      <img src={fourthIconUrl} alt="PROMINOX" />
                      <p>Fácil dilatación y contracción</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="steel-lineup">
        <Container>
          <h3 className="wow fadeInUp">línea de productos</h3>
          <Row className="wow fadeInUp mt-4 mt-md-0" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={rolloImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={laminaImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={placaImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={flejeImgUrl} alt="Accesorios para barandales" />
            </Col>
          </Row>
          <Row className="my-0 my-md-4 wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img
                src={ornamentalRedondaImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img
                src={ornamentalRectangularImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img
                src={ornamentalCuadradaImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={tuberiaPipeImgUrl} alt="Accesorios para barandales" />
            </Col>
          </Row>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={soleraImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={discoImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={blankImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={barraRedondaImgUrl} alt="Accesorios para barandales" />
            </Col>
          </Row>
          <Row className="my-0 my-md-4 wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={barraCuadradaImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img
                src={antiderrapanteImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={anguloImgUrl} alt="Accesorios para barandales" />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="carousel--steel__applications-container">
        <div className="carousel-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>

        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--1">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                <span>Cocinas industriales</span>
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--2">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                equipo de refinación petróleo
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--3">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                adornos arquitectónicos
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--4">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                equipos de laboratorio
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <Contact />
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
