import gql from "graphql-tag";

const CATEGORIES_QUERY = gql`
  query Categories {
    categories {
      id
      name
      Description
      slug
      imagen {
        url
      }
    }
  }
`;

export default CATEGORIES_QUERY;
