import React from "react";

// Helmet
import Helmet from "react-helmet";

// Bootstrap
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

// Custom styles
import "./legal.styles.scss";

export default function ReturnPolicy() {
  return (
    <>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Políticas de devolución - Prominox</title>
        <meta
          name="description"
          content="30+ años de experiencia. Líderes en la comercialización de acero inoxidable, aluminio, tubería conduit y accesorios para barandales."
        />
        <meta
          name="keywords"
          content="Herrajes, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Políticas de devolución - Prominox" />
        <meta
          itemprop="description"
          content="30+ años de experiencia. Líderes en la comercialización de acero inoxidable, aluminio, tubería conduit y accesorios para barandales."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Políticas de devolución - Prominox"
        />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Políticas de devolución - Prominox"
        />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero--legal">
        <Jumbotron fluid className="jumbotron--legal"></Jumbotron>
      </section>
      <div className="legal__info">
        <Container>
          <h1 className="sm text-center">
            Política sobre reclamaciones de clientes y devoluciones de material
          </h1>
          <p>Estimado Cliente,</p>
          <p>Prominox agradece su preferencia.</p>
          <p>
            Por este medio hacemos de su conocimiento las políticas y
            procedimientos relacionados con las reclamaciones y/o devoluciones
            de los materiales adquiridos.
          </p>
          <p>
            <span>Objeto: </span>Las disposiciones de esta “Política sobre
            reclamaciones de clientes y devoluciones de material” (en adelante
            la “Política”) son aplicables a todos los productos catalogados como
            de primera calidad, adquiridos directamente a través de alguno de
            los canales oficiales de Promotora Industrial GIM, S.A. de C.V. (en
            adelante “Prominox”), sean estos digitales o sucursales físicas.
          </p>
          <p className="legal__info__sub-heading">
            Reclamaciones, cambios y devoluciones por daños o defectos en el
            material.
          </p>
          <p>
            <span>Periodo de reclamación:</span> El Cliente se obliga a realizar
            una inspección profunda de los materiales adquiridos a fin de
            identificar cualquier defecto preexistente. Para tal efecto, los
            Clientes contarán con los siguientes plazos para notificar a
            Prominox de los defectos encontrados:
          </p>
          <ul>
            <li>
              En caso de que el material presente daños de maniobra o defectos
              visibles, esto deberá notificarse <span>inmediatamente</span>, por
              el representante o el subcontratado del cliente, antes de ser
              cargado en su transporte, cuando se trate de materiales
              recolectados en las instalaciones de Prominox, o máximo durante la
              descarga, cuando se trate de materiales entregados en las
              instalaciones de los Clientes utilizando equipo de transporte de
              Prominox o subcontratado por Prominox.
            </li>
            <li>
              Para daños estéticos no visibles durante el proceso de carga y
              descarga, el Cliente contará con un periodo de{" "}
              <span>30 (treinta) días naturales</span>, contados a partir de la
              fecha de recepción del material para realizar la reclamación
              correspondiente.
            </li>
            <li>
              Para defectos no estéticos y no visibles durante el proceso de
              carga y descarga, el Cliente contará con un periodo de{" "}
              <span>60 (sesenta) días naturales</span>, contados a partir de la
              fecha de recepción del material para realizar la reclamación
              correspondiente.
            </li>
          </ul>
          <p>
            <span>Procedimiento de reclamación:</span> Toda reclamación debe
            seguir el siguiente procedimiento:
          </p>
          <ol>
            <li>
              El Cliente deberá realizar la inspección del material de
              conformidad con los plazos establecidos en la sección anterior.
              Deberá notificar a su ejecutivo de ventas o al contacto de calidad
              correspondiente. En caso de ser al ejecutivo de ventas, este
              último será quien canalice la solicitud al departamento de calidad
              de Prominox para su seguimiento.
            </li>
            <li>
              Una vez recibida la solicitud, el departamento de Control de
              Calidad contará con un plazo de 3 (tres) días hábiles para emitir
              una respuesta preliminar sobre la procedencia de la solicitud.
              Para aquellos casos en donde se determine que es necesario
              realizar un análisis sobre el material en disputa, el periodo de
              respuesta podrá ampliarse y sujetarse a condiciones especiales.
              {/* <ul>
                <li>
                  Elaboración de la queja: 24 horas hábiles máximo desde la
                  recepción.
                </li>
                <li>
                  Respuesta preliminar a la queja: 72 horas hábiles desde la
                  elaboración, a excepción de cuando se necesite inspeccionar
                  físicamente el material reclamado.
                </li>
                <li>
                  Queda en la responsabilidad del cliente notificar cualquier
                  anomalía sobre los materiales recibidos:
                  <ul>
                    <li>
                      De inmediato, antes de la descarga, en caso de material
                      que presenta evidentes daños de maniobra
                    </li>
                    <li>
                      Dentro de los 30 días desde la recepción para defectos
                      estéticos
                    </li>
                    <li>
                      Dentro de los 60 días desde la recepción para defectos no
                      visibles.
                    </li>
                  </ul>
                </li>
              </ul> */}
            </li>
            <li>
              Después de realizar el análisis correspondiente, se comunicará al
              cliente si la reclamación es procedente o no. En caso de proceder
              por causas imputables a Prominox, aplicará lo establecido en las{" "}
              <span>
                Reglas generales, excepciones y restricciones aplicables a
                reclamaciones y devoluciones.
              </span>
            </li>
            <li>
              Prominox no se hará responsable de los cargos consecuenciales
              derivados de la reclamación.
            </li>
            <li>
              Los productos que sean devueltos no deberán presentar daños o
              condiciones ajenas a los reclamados por el Cliente y aceptados por
              Prominox, en caso contrario, no procederá la devolución.
            </li>
            <li>
              No se aceptarán devoluciones sobre productos maquilados,
              transformados o de cualquier manera alterados.
            </li>
            {/* <li>
              No se acepta reclamación o devolución de material por:
              <ul>
                <li>Producto procesado o producto en partes.</li>
                <li>
                  Comisión o cambios no notificados a alguna especificación o
                  requisito especial no declarado en la Orden de Compra del
                  cliente
                </li>
                <li>
                  Cara inferior en acabado, tono y rugosidad cuando no haya sido
                  especificado por el cliente desde la etapa de cotización y se
                  haya contemplado el costo adicional de un acabado especifico.
                </li>
                <li>
                  Producto o material vendido con clasificación de venta de
                  segunda calidad o chatarra.
                </li>
                <li>
                  Diferencias en peso imputables a diferencia entre basculas
                  debidamente calibradas ante un organismo certificado.
                </li>
              </ul>
            </li>
            <li>
              Para venta de material en bobina o fleje Prominox garantiza la
              calidad del 97% del material.
            </li>
            <li>
              De aceptar una devolución cuyo producto se encuentre en las mismas
              condiciones de la venta inicial y cuyas razones de devolución no
              dependan de Prominox se podrá hacer un cargo adicional por gastos
              y trámites administrativos.
            </li>
            <li>
              Los gastos de maniobras, almacenamiento, inspecciones, sorteos de
              producto y transportación deberán ser absorbidos por el cliente
              cuando no haya una responsabilidad de origen hacia Prominox por
              concepto de producto no conforme o fuera de especificaciones a lo
              solicitado.
            </li>
            <li>
              No aplican cambios físicos derivados de una devolución; si el
              cliente requiere un producto diferente a lo entregado se cancelará
              la factura a través de nota de crédito y se generará una nueva por
              el producto solicitado.
            </li>
            <li>
              Toda Nota de Crédito será emitida al haber confirmado que el
              material devuelto coincida con lo pactado y en un lapso no mayor a
              los 2 días hábiles desde la recepción del material.
            </li>
            <li>
              Previa negociación, el material podría permanecer en las
              instalaciones del cliente y ser dispuesto a conveniencia. En este
              caso Prominox emitirá una nota de crédito por la diferencia del
              valor comercial del material contra el precio de mercado de la
              chatarra.
            </li> */}
          </ol>
          <p className="legal__info__sub-heading">
            Devoluciones de material sin proceso de reclamación de calidad
          </p>
          <p>
            Todos los materiales podrán ser devueltos siempre y cuando se
            indique el motivo por el cual se solicita la devolución, así como se
            cumplan con las siguientes reglas: 1) El material se encuentre en el
            mismo estado en que fue adquirido por el Cliente; 2) La devolución
            se sujete al procedimiento establecido en la presente política, y;
            3) Ni el Cliente ni el material a devolver se encuentren dentro de
            alguno de los supuestos de excepción establecidos en la presente
            política.
          </p>
          <p>
            Si el cliente requiere un producto diferente al adquirido
            originalmente, se deberá cancelar la factura de origen, generar una
            nota de crédito y facturar el nuevo producto. Toda devolución estará
            sujeta a revisión y aprobación de la empresa.
          </p>
          <p>
            <span>Periodo para la devolución:</span> Todo material podrá ser
            devuelto, dentro de los 30 (treinta) días naturales contados a
            partir de la fecha de emisión de la factura que ampare la compra.
          </p>
          <p>
            <span>Procedimiento de devolución:</span>Toda devolución debe seguir
            el siguiente procedimiento:
          </p>
          <ol>
            <li>
              El cliente deberá de coordinar la solicitud a través de su
              ejecutivo de ventas, misma que deberá realizarse dentro del
              Periodo para la devolución.
            </li>
            <li>
              Una vez coordinada la solicitud, el Cliente deberá acudir a la
              sucursal donde adquirió los Productos a fin de que el ejecutivo de
              ventas, en conjunto con el resto del equipo Prominox, se aseguren
              de que el material a devolver se encuentre en el mismo estado en
              que fue adquirido.
            </li>
            <li>
              Todos los gastos inherentes al almacenaje, transporte, maniobras,
              sorteos de producto, pérdidas consecuenciales, o cualquier otro
              derivado o relacionado con la devolución de los productos, es
              responsabilidad exclusiva del Cliente.
            </li>
            <li>
              En caso de que se determine que la devolución es procedente, se
              emitirá una nota de crédito a favor del cliente y se cancelará la
              factura original del material devuelto.
            </li>
          </ol>
          <p className="legal__info__sub-heading">
            Reglas generales, excepciones y restricciones aplicables a
            reclamaciones y devoluciones
          </p>
          <p>
            Todas las devoluciones o reclamaciones se sujetarán a las siguientes
            reglas generales, excepciones y restricciones:
          </p>
          <ol>
            <li>
              Toda reclamación que se origine por diferencias de peso será
              evaluada en conjunto para determinar el origen de la diferencia y
              el procedimiento aplicable a cada caso concreto.
            </li>
            <li>
              No se aceptarán devoluciones ni cambios en los siguientes casos:
              <ul>
                <li>
                  Productos procesados, transformados o de cualquier manera
                  alterados.
                </li>
                <li>
                  Omisiones, errores o cambios no notificados sobre
                  especificaciones o características no señalados en la orden de
                  compra.
                </li>
                <li>
                  Cara inferior en acabado, tono y rugosidad cuando no haya sido
                  especificado por el cliente desde la etapa de cotización y se
                  haya contemplado el costo adicional de un acabado especifico.
                </li>
                <li>
                  Materiales clasificados como “Chatarra” o “Segunda calidad”.
                </li>
                <li>
                  Productos procesados o transformados por Prominox, con
                  características especiales solicitadas por cliente, salvo que
                  la devolución sea resultado de un defecto de calidad imputable
                  a Prominox.
                </li>
                <li>Productos no comerciales o de lento movimiento.</li>
                <li>
                  Diferencias en peso imputables a diferencia entre basculas
                  debidamente calibradas ante un organismo certificado.
                </li>
              </ul>
            </li>
            <li>
              Se aplicará un cargo administrativo del 3% (tres por ciento) del
              valor total de los productos adquiridos para cualquier cambio o
              devolución, con excepción de aquellas devoluciones o cambios por
              causas imputables a Prominox.
            </li>
            <li>
              Las sustituciones de material solo proceden en caso de que el
              material originalmente adquirido cuente con algún defecto y la
              sustitución se realizará por un producto de las mismas
              características.
            </li>
            <li>
              Toda nota de crédito será emitida dentro de los 2 (dos) días
              hábiles posteriores a que se haya confirmado que el material
              devuelto coincide con lo pactado y el material haya sido recibido
              a entera satisfacción de Prominox, en sus instalaciones. Las
              devoluciones en efectivo no están permitidas.
            </li>
            <li>
              Previa negociación por ambas partes el material podría permanecer
              en las instalaciones del cliente y ser dispuesto a conveniencia.
            </li>
          </ol>
          <p className="legal__info__sub-heading">
            ¡Gracias por su preferencia!
          </p>
        </Container>
      </div>
    </>
  );
}
