import React from "react";
import "./articles.styles.scss";

// Components
import { BlogItem } from "../Card";

// Dependencies
import Masonry from "react-masonry-css";

const breakpoints = {
  default: 2,
  991: 1,
};

const Articles = ({ articles }) => {
  // console.log(articles);
  return (
    <Masonry
      breakpointCols={breakpoints}
      className="blog__item-container"
      columnClassName="blog__item-container__column"
    >
      {articles.map((article, i) => (
        <BlogItem article={article} key={`article__${article.id}`} id={i + 1} />
      ))}
    </Masonry>
  );
};

export default Articles;
