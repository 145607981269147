import React from "react";
import Card from "react-bootstrap/Card";

import "./customCards.styles.scss";

import calendarIconUrl from "../../assets/calendar-icon-contact.svg";
import phoneIconUrl from "../../assets/phone-icon-contact.svg";
import locationIconUrl from "../../assets/location-icon-contact.svg";

export function BranchCard({
  imgUrl,
  cityName,
  branchPhone,
  branchSecondaryPhone,
  branchSchedule,
  branchLocation,
  branchLocationLink,
  secondImgUrl,
}) {
  return (
    <Card className="branch-card">
      <Card.Header>
        <Card.Img variant="top" src={imgUrl} />
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <Card.Title>{cityName}</Card.Title>
          {secondImgUrl ? (
            <img
              className="second-img"
              src={secondImgUrl}
              alt="Comunícate con nosotros"
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          <img src={phoneIconUrl} alt="Comunícate con nosotros" />{" "}
          <a href={`tel:${branchPhone}`}>{branchPhone}</a>
        </div>
        {branchSecondaryPhone ? (
          <div>
            <img src={phoneIconUrl} alt="Comunícate con nosotros" />{" "}
            <a href={`tel:${branchSecondaryPhone}`}>{branchSecondaryPhone}</a>
          </div>
        ) : (
          <></>
        )}
        <div>
          <img src={calendarIconUrl} alt="Comunícate con nosotros" />{" "}
          <p>{branchSchedule}</p>
        </div>
        <div>
          <img src={locationIconUrl} alt="Comunícate con nosotros" />{" "}
          <a href={branchLocationLink}>{branchLocation}</a>
        </div>
      </Card.Body>
    </Card>
  );
}
