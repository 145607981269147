import React from "react";
import { useQuery } from "@apollo/react-hooks";

const QuerySlug = ({ children, query, slug }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: slug },
  });

  if (loading)
    return (
      <div className="container text-center">
        <p>Cargando...</p>
      </div>
    );
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default QuerySlug;
