import React from "react";
import { Link } from "react-router-dom";
import "./card.styles.scss";

export const Card = ({ article }) => {
  const imageUrl =
    process.env.NODE_ENV !== "development"
      ? process.env.REACT_APP_BACKEND_URL + article.image.url
      : article.image.url;
  return (
    <Link to={`/blog/articulo/${article.id}`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top">
          <img src={imageUrl} alt={article.image.url} height="100" />
        </div>
        <div className="uk-card-body">
          <p id="category" className="uk-text-uppercase">
            {article.category.name}
          </p>
          <p id="title" className="uk-text-large">
            {article.title}
          </p>
        </div>
      </div>
    </Link>
  );
};

export const ArticleCard = ({ article }) => {
  const imageUrl = article.imagen.url;

  return (
    <Link to={`/blog/articulo/${article.id}`} className="article-card">
      <div className="article-card-media-top">
        <img src={imageUrl} alt={article.imagen.url} height="100" />
      </div>
      {/* <div> */}
      {/* <div className="article-card-body">
          <h4 id="title">{article.title}</h4>
        </div> */}
      {/* </div> */}
      <div className="article-card-body">
        <h4 id="title">{article.title}</h4>
        {/* <div className="white-line"></div>
        <p id="category">{article.description}</p> */}
      </div>
      <div className="article-card-body--hover">
        <h4 id="title">{article.title}</h4>
        <div className="white-line"></div>
        <p id="category">{article.description}</p>
      </div>
    </Link>
    // <Col xs={12} lg={6}>
    // </Col>
  );
};

export const BlogItem = ({ article, id }) => {
  // Develop uncomment
  // const imageUrl =
  //   process.env.NODE_ENV !== "development"
  //   // ? article.imagen.url
  //     ?
  //       process.env.REACT_APP_BACKEND_URL + article.imagen.url
  //     : article.imagen.url;

  // Production uncomment
  const imageUrl = article.imagen?.url;
  // console.log(article);
  return (
    <Link
      to={`/blog/articulo/${article.id}`}
      className="blog__item wow fadeInUp"
    >
      <div className="blog__item-holder">
        <div className="blog__item__image-container">
          <img
            src={imageUrl}
            alt={article.title}
            className="blog__item__image"
          />
        </div>
        <div className="blog__item__content-container">
          <div className="blog__item__content">
            <h3 className="blog__item__title">{article.title}</h3>
            <div className="white-line"></div>
            <p className="blog__item__text">{article.description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const CategoryCard = ({ category }) => {
  // const imageUrl =
  //   process.env.NODE_ENV !== "development"
  //     ? process.env.REACT_APP_BACKEND_URL + category.imagen.url
  //     : category.imagen.url;
  const imageUrl = category.imagen?.url;
  // console.log(category.slug, " slug");

  return (
    <Link
      to={`/blog/categoria/${category.id}`}
      className="category__item wow fadeInUp"
      data-wow-delay=".3s"
      // onClick={() => console.log(`/blog/categoria/${category.id}`)}
    >
      <div className="category__item-holder">
        <div className="category__item__image-container">
          <img
            src={imageUrl}
            alt={category.name}
            className="category__item__image"
          />
        </div>
        <div className="category__item__content-container">
          <div className="category__item__content">
            <h5 className="category__item__title">{category.name}</h5>
            <div className="white-line"></div>
            <p className="category__item__description">
              {category.description}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
