import React from "react";
import { Link } from "react-router-dom";

import "./buttons.styles.scss";

export function BlueButton({ text, link, target }) {
  return link ? (
    <Link to={`/${target}`}></Link>
  ) : (
    <a href={target} className="btn-quote--blue">
      <span>{text}</span>
    </a>
  );
}
