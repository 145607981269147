import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import { Helmet } from "react-helmet";

import "./aluminum.styles.scss";

import aluminioIconUrl from "../../assets/img-home-aluminio.png";
import popUp from "../../assets/popUp.jpeg";

// import certification1ImgUrl from "../../assets/logo-cfe.jpg";
// import certification2ImgUrl from "../../assets/logo-imss.jpg";
// import certification3ImgUrl from "../../assets/logo-ance.jpg";
// import certification4ImgUrl from "../../assets/logo-ul.jpg";
// import certification5ImgUrl from "../../assets/logo-lapem.jpg";

import firstIconUrl from "../../assets/icn-aluminio-04.svg";
import secondIconUrl from "../../assets/icn-aluminio-01.svg";
import thirdIconUrl from "../../assets/icn-02.svg";
import fourthIconUrl from "../../assets/icn-06.svg";
import fifthIconUrl from "../../assets/icn-aluminio-01.svg";
import sixthIconUrl from "../../assets/icn-aluminio-05.svg";
import seventhIconUrl from "../../assets/icn-aluminio-08.svg";
import eigthIconUrl from "../../assets/icn-07.svg";
import ninethIconUrl from "../../assets/icn-01.svg";
import tenthIconUrl from "../../assets/icn-aluminio-07.svg";
import eleventhIconUrl from "../../assets/icn-aluminio-03.svg";
import twelveIconUrl from "../../assets/icn-aluminio-02.svg";

import aluminioLaminaImgUrl from "../../assets/aluminio-lamina.jpg";
import aluminioBlankImgUrl from "../../assets/aluminio-blank.jpg";
import aluminioPlacaImgUrl from "../../assets/aluminio-placa-liso.jpg";
import aluminioFlejeImgUrl from "../../assets/aluminio-fleje.jpg";
import aluminioAntiImgUrl from "../../assets/aluminio-placa-antiderrapante.jpg";
import aluminioRoBlancoImgUrl from "../../assets/aluminio-rollo-blanco.jpg";
import aluminioPlBlancoImgUrl from "../../assets/aluminio-placa-blanco.jpg";
import aluminioLisoImgUrl from "../../assets/aluminio-rollo-liso.jpg";

// import firstSlideImgUrl from "../../assets/accesorios-barandales-slide-1.png";
// import secondSlideImgUrl from "../../assets/accesorios-barandales-slide-2.png";
// import thirdSlideImgUrl from "../../assets/accesorios-barandales-slide-3.png";

import AluminumVideo from "../../assets/videos/ALUMINIO.mp4";

import Contact from "../Contact/Contact";

export default function Aluminum() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Aluminio</title>
        <meta
          name="description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta
          name="keywords"
          content="aluminio, venta de aluminio, placa antiderrapante, rollo liso natural, rollo blanco wash, placa liso natural, blank, serie 1050, serie 1100, serie 3003, serie 5052, serie 5454, serie 3001"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Aluminio" />
        <meta
          itemprop="description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Aluminio" />
        <meta
          property="og:description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Aluminio" />
        <meta
          name="twitter:description"
          content="Empresa con más de 30 años de experiencia en el mercado, contamos con producto a la medida y entregas just in time con una cobertura nacional de 20 sucursales en México"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-aluminum">
          <video
            autoPlay
            loop
            muted
            className="video"
            // style={{ objectFit: "cover" }}
          >
            <source src={AluminumVideo} type="video/mp4" />
          </video>
          <Container
          // style={{
          //   position: "absolute",
          //   width: "100%",
          //   top: "0",
          //   height: "100%",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          >
            <h1 className="wow fadeInUp">aluminio</h1>
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".5s"
            >
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aluminioIconUrl} alt="Aluminio" />
                  <p>Material no magnético</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aluminioIconUrl} alt="Aluminio" />
                  <p>Conductividad Eléctrica</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aluminioIconUrl} alt="Aluminio" />
                  <p>Resistencia a la corrosión</p>
                </Col>
                <Col xs={12} lg={6} className="jumbotron--hero__card">
                  <img src={aluminioIconUrl} alt="Aluminio" />
                  <p>Buenas propiedades mecánicas</p>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      <section className="carousel--aluminum-container mt-n5">
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--aluminum-item carousel--aluminum-item--1">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 1000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="d-flex wow fadeInUp" data-wow-delay=".3s">
                  <h4>1050</h4>
                  <h4>1100</h4>
                  <h4>1350</h4>
                </div>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={fifthIconUrl} alt="PROMINOX" />
                      <p>Alta conductividad eléctrica</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={ninethIconUrl} alt="PROMINOX" />
                      <p>Alta conductividad térmica</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>Alta resistencia a la corrosión</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--2">
            <div className="carousel--aluminum-item carousel--aluminum-item--2">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 2000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  2024
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Buenas propiedades mecánicas</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>Alta resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={tenthIconUrl} alt="PROMINOX" />
                      <p>Presentación en sandwich</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--3">
            <div className="carousel--aluminum-item carousel--aluminum-item--3">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 3000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="d-flex wow fadeInUp" data-wow-delay=".3s">
                  <h4>3003</h4>
                  <h4>3004</h4>
                  <h4>3001</h4>
                  <h4>3105</h4>
                </div>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Buenas propiedades mecánicas</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>Alta resistencia a la corrosión</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--4">
            <div className="carousel--aluminum-item carousel--aluminum-item--4">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 4000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  4030
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={sixthIconUrl} alt="PROMINOX" />
                      <p>Buenas propiedades mecánicas</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--5">
            <div className="carousel--aluminum-item carousel--aluminum-item--5">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 5000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <div className="d-flex wow fadeInUp" data-wow-delay=".3s">
                  <h4>5005</h4>
                  <h4>5052</h4>
                  <h4>5454</h4>
                  <h4>5754</h4>
                </div>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Buenas propiedades mecánicas</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={fourthIconUrl} alt="PROMINOX" />
                      <p>Excelente soldabilidad</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>Alta resistencia a la corrosión marina</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--6">
            <div className="carousel--aluminum-item carousel--aluminum-item--6">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 6000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  6061
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={secondIconUrl} alt="PROMINOX" />
                      <p>Media resistencia a cargas</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={thirdIconUrl} alt="PROMINOX" />
                      <p>Buena formabilidad</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--7">
            <div className="carousel--aluminum-item carousel--aluminum-item--7">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 7000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  7075
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={seventhIconUrl} alt="PROMINOX" />
                      <p>Alta resistencia mecánica</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item--8">
            <div className="carousel--aluminum-item carousel--aluminum-item--8">
              <div className="carousel--aluminum-item__heading">
                <h2 className="wow fadeInUp">serie 8000</h2>
                <h3 className="wow fadeInUp" data-wow-delay=".3s">
                  aleaciones comunes
                </h3>
                <h4 className="wow fadeInUp" data-wow-delay=".3s">
                  8081
                </h4>
              </div>
              <div
                className="carousel--aluminum-item__properties wow fadeInUp"
                data-wow-delay=".7s"
              >
                <h5>propiedades</h5>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Facilidad de laminar</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={eleventhIconUrl} alt="PROMINOX" />
                      <p>Resistencia a la tensión</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <div className="carousel--aluminum-item__properties__card">
                      <img src={twelveIconUrl} alt="PROMINOX" />
                      <p>Buena plasticidad</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      {/* <section className="pipeline-characteristics">
        <Jumbotron fluid className="jumbotron--hero-pipeline__characteristics">
          <Container>
            <h1>características</h1>
            <div className="jumbotron--hero__cards__container">
              <Row className="justify-content-md-center">
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={seventhIconUrl} alt="PROMINOX" />
                    <p>Acero de primera calidad</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={thirdIconUrl} alt="PROMINOX" />
                    <p>Rolado en frío</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={secondIconUrl} alt="PROMINOX" />
                    <p>Pintura anticorrosiva en el interior</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={firstIconUrl} alt="PROMINOX" />
                    <p>Cuerdas libres de rebaba</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={fourthIconUrl} alt="PROMINOX" />
                    <p>Galvanizado exterior</p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="carousel--steel-item__properties__card">
                    <img src={sixthIconUrl} alt="PROMINOX" />
                    <p>Soldadura de alta frecuencia</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section> */}
      <section className="steel-lineup">
        <Container>
          <h3 className="wow fadeInUp">línea de productos</h3>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioLaminaImgUrl} alt="Tubería Conduit" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0 text-left">
              <img src={aluminioBlankImgUrl} alt="Tubería Conduit" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioPlacaImgUrl} alt="Tubería Conduit" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioFlejeImgUrl} alt="Tubería Conduit" />
            </Col>
          </Row>
          <Row className="my-0 my-md-4 wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioAntiImgUrl} alt="Tubería Conduit" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioRoBlancoImgUrl} alt="Tubería Conduit" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioPlBlancoImgUrl} alt="Tubería Conduit" />
            </Col>
            <Col xs={6} md={3} className="mb-3 mb-md-0">
              <img src={aluminioLisoImgUrl} alt="Tubería Conduit" />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="carousel--aluminum__applications-container">
        <div className="carousel-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--1">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                cocinas industriales
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminio + cobre. aleaciones comunes: 2024</h4>
                </div>
                <div>
                  <h4>propiedades</h4>
                  <ul>
                    <li>buenas propiedades mecánicas</li>
                    <li>alta resistencia a la corrosión</li>
                    <li>presentación en sandwich</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--2">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                Soldaduras
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminio + silicio.</h4>
                  <h4>aleaciones comunes: 4030</h4>
                </div>
                <div>
                  <h4>propiedades</h4>
                  <ul>
                    <li>bajo punto de fusión</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--3">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                barcos
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminio + manganeso + magnesio</h4>
                  <h4>aleaciones comunes: 5005, 5052, 5454, 5754</h4>
                </div>
                <div>
                  <h4>propiedades</h4>
                  <ul>
                    <li>altas propiedades mecánicas</li>
                    <li>alta soldabilidad</li>
                    <li>alta resistencia a la corrosión marina</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--4">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                estructuras en <span>aviones</span>
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminio + zinc</h4>
                  <h4>aleaciones comunes: 7075</h4>
                </div>
                <div>
                  <h4>propiedades</h4>
                  <ul>
                    <li>alta resistencia mecánica</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--aluminum__applications__item carousel--aluminum__applications__item--5">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                aire acondicionado
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <h4>aluminio + hierro</h4>
                  <h4>aleaciones comunes: 8081</h4>
                </div>
                <div>
                  <h4>propiedades</h4>
                  <ul>
                    <li>facilidad de laminar</li>
                    <li>resistencia a la tensión</li>
                    <li>buena plasticidad</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <Contact />
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
