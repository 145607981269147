import React, { useState, useEffect } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";

import { Helmet } from "react-helmet";

import "../Jumbotron/jumbotron.styles.scss";
import "./centroServicio.styles.scss";
import Contact from "../Contact/Contact";

import CutVideo from "../../assets/videos/CORTE.mp4";
import PolishVideo from "../../assets/videos/PULIDO.mp4";
import LeveledVideo from "../../assets/videos/NIVELADO.mp4";

import ServiceCenterVideo from "../../assets/videos/CENTRO-DE-SERVICIO.mp4";

import popUp from "../../assets/popUp.jpeg";

export default function CentroServicio() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Centro de Servicio</title>
        <meta
          name="description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta
          name="keywords"
          content="acero inoxidable, aluminio, tubería conduit, herrajes, centro de servicio, san luis potosi"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Centro de Servicio" />
        <meta
          itemprop="description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Centro de Servicio" />
        <meta
          property="og:description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Centro de Servicio" />
        <meta
          name="twitter:description"
          content="Nuestro Centro de Servicio cuenta con la maquinaria más novedosa que ofrece acabados especializados de la mejor calidad ubicado en el centro de la República Mexicana."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-centro-de-servicio mb-0">
          <video autoPlay loop muted className="video">
            <source src={ServiceCenterVideo} type="video/mp4" />
          </video>
          <Container>
            <h1 className="wow fadeInUp">centro de servicio</h1>
          </Container>
        </Jumbotron>
      </section>
      <section className="centro-de-servicio">
        <Container>
          <h2 className="wow fadeInDown">nuestras líneas de producción</h2>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} className="wow fadeInUp" data-wow-delay=".4s">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Corte</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Nivelado</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Pulido</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} className="wow fadeInUp" data-wow-delay=".6s">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div>
                      <ul>
                        <li>
                          <span>ESPESOR:</span>
                          <p>Mínimo: 0.61 mm. / CALIBRE 24</p>
                          <p>Máximo: 6.35 mm. ¼ PULGADA</p>
                        </li>
                        <li>
                          <span>ANCHO:</span>
                          <p>Mínimo: 609.6 mm. (2 PIES) </p>
                          <p>Máximo: 1,828.8 mm. (6 PIES)</p>
                        </li>
                        <li>
                          <span>LARGO:</span>
                          <p>Mínimo: 609.66 mm. (2 PIES) </p>
                          <p>Máximo: 12,192 mm. (40 PIES)</p>
                        </li>
                        <li>
                          <span>PESO:</span>
                          <p>Mínimo: 500 kg.</p>
                          <p>Máximo: 3,000 kg.</p>
                        </li>
                      </ul>
                      <video controls muted className="video">
                        <source src={CutVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div>
                      <ul>
                        <li>
                          <span>ESPESOR:</span>
                          <p>Mínimo: 0.61 mm. / CALIBRE 24</p>
                          <p>Máximo 6.35MM ¼ PULGADA</p>
                        </li>
                        <li>
                          <span>ANCHO:</span>
                          <p>Mínimo: 609.6 mm. (2 PIES) </p>
                          <p>Máximo: 1,828.8 MM (6 PIES)</p>
                        </li>
                        <li>
                          <span>LARGO:</span>
                          <p>Mínimo 609.66 mm. (2 PIES)</p>
                          <p>Máximo: 12,192 mm. (40 PIES)</p>
                        </li>
                        <li>
                          <span>PESO:</span>
                          <p>Mínimo: 500 KG</p>
                          <p>Máximo: 3,000 KG</p>
                        </li>
                      </ul>
                      <video controls muted className="video">
                        <source src={LeveledVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div>
                      <ul className="third">
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Scotch brite 1 Seco Fine
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Scotch brite 2 Seco
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Scotch brite 3 Seco
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P4 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P5 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Hairline Normal
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Hairline Mirror
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">BA</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">
                            Mirror P8 Normal
                          </p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P2 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P3 seco</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P3 húmedo</p>
                        </li>
                        <li>
                          <p className="p-0 mt-0 d-inline-block">P4 húmedo</p>
                        </li>
                      </ul>
                      <video controls muted className="video">
                        <source src={PolishVideo} type="video/mp4" />
                      </video>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
      <Contact />
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
