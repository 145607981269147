import React from "react";

import { Link } from "react-router-dom";

// Assets
import B18 from "../../assets/B18.jpg";

function NewArticle() {
  return (
    <div className="blog-article">
      <div className="blog-article__media">
        <div>
          <img src={B18} className="img-fluid" alt="Prominox" />
          <div className="blog-article__media__title">
            <h1>
              ¿Cómo limpiar el <span>acero inoxidable?</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="blog-article__body">
        <p>
          Para limpiar el acero inoxidable y mantenerlo en buen estado, puedes
          seguir los siguientes pasos:
        </p>
        <p>
          Reúne los materiales: Necesitarás agua tibia, detergente suave, un
          paño suave o una esponja no abrasiva y un paño de microfibra o un paño
          de algodón.
        </p>
        <ol>
          <li>
            <span>
              {" "}
              Limpieza inicial: Puedes comenzr limpiando la superficie de acero
              inoxidable con agua tibia y un detergente suave. Aplica el
              detergente en el paño o esponja y frota suavemente la superficie
              en la dirección del grano del acero. Evita utilizar limpiadores
              abrasivos o estropajos duros que puedan rayas la superficie.
            </span>
          </li>
          <li>
            <span>
              {" "}
              Enjuage y seque: Después de limpiar, enjuaba bien la superficie
              con agua limpia para eliminar cualquier residuo de detergente. A
              continuación, seca la superficie con un paño de microfibra o un
              paño de algodón limpio y seco. Esto ayudará a prevenir manchas de
              agua o marcas de secado.
            </span>
          </li>
          <li>
            <span>
              Eliminación de manchas y marcas: Si encuentras manchas o marcas
              más difíciles de eliminar, puedes utilizar algunas opciones
              adicionales de limpieza. Una mezcla de agua y vinage blanco en
              partes iguales puede ser efectiva para eliminar manchas o huellas
              dactilares persistentes. También puedes utilizar un limpiador
              específico para acero inoxidable, siguiendo las instrucciones del
              fabricante.
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default NewArticle;
