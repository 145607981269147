import React from "react";

// Helmet
import Helmet from "react-helmet";

// Bootstrap
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import CardDeck from "react-bootstrap/CardDeck";

// Components
import Contact from "../../components/Contact/Contact";
import { BranchCard } from "../../components/CustomCards/CustomCards";

// Custom styles
import "./landing.styles.scss";

// Media
import jupiterImgUrl from "../../assets/Jupiter_Logo.png";
import VideoIntro from "../../assets/videos/video_intro.mp4";
import prominoxLogoUrl from "../../assets/logo-prominox--white.png";
import gimLogo8Url from "../../assets/logo-gim-8.png";
import logoPeasa2 from "../../assets/logo-peasa2-8.png";
import logoPeasa8 from "../../assets/logo-peasa-8.png";
import logoProminox from "../../assets/logo-prominox.svg";
import logoGimtrac from "../../assets/logo-gimtrac-8.png";
import logoIntertek from "../../assets/Recurso-5-8.png";
import logoCFE from "../../assets/logo-cfe.jpg";
import logoIMSS from "../../assets/logo-imss.jpg";
import logoAnce from "../../assets/logo-ance.jpg";
import logoUl from "../../assets/logo-ul.jpg";
import logoLapem from "../../assets/logo-lapem.jpg";

// Media benefits
import benefit1IconUrl from "../../assets/icn-home6.png";
import benefit2IconUrl from "../../assets/icn-home8.png";
import benefit3IconUrl from "../../assets/icn-home1.png";
import benefit4IconUrl from "../../assets/icn-home3.png";
import benefit5IconUrl from "../../assets/icn-home4.png";
import benefit6IconUrl from "../../assets/icn-home5.png";
import benefit7IconUrl from "../../assets/icn-home7.png";
import benefit8IconUrl from "../../assets/icn-home2.png";

// Branches media
import agsImgUrl from "../../assets/ags.jpg";
import chihImgUrl from "../../assets/img-chihuahua.jpg";
import clcImgUrl from "../../assets/img-culiacan-b.jpg";
import gdlImgUrl from "../../assets/img-guadalajara.jpg";
import iztaImgUrl from "../../assets/img-iztapalapa-b.jpg";
import leonImgUrl from "../../assets/img-leon.jpg";
import mazatImgUrl from "../../assets/img-mazatlan.jpg";
import montImgUrl from "../../assets/img-monterrey.jpg";
import moreImgUrl from "../../assets/img-morelia.jpg";
import pueImgUrl from "../../assets/img-puebla.jpg";
import quereImgUrl from "../../assets/img-queretaro-b.jpg";
import reynImgUrl from "../../assets/img-reynosa-2.jpg";
// import saltiImgUrl from "../../assets/img-saltillo.jpg";
import slpImgUrl from "../../assets/sanluis.jpg";
import tijImgUrl from "../../assets/img-tijuana.jpg";
import tlalnImgUrl from "../../assets/mexico.jpg";
import torrnImgUrl from "../../assets/img-torreon.jpg";
import vcrzImgUrl from "../../assets/img-veracruz.jpg";
import tmpSecondImgUrl from "../../assets/tampico-second.jpg";

// Barandal icon
import tuberiaIconUrl from "../../assets/img-home-tuberia.png";

// Products media
import paredExDelgadaImgUrl from "../../assets/tuberia-conduit-pared-extradelgada.jpg";
import paredDelgadaImgUrl from "../../assets/tuberia-conduit-pared-delgada.jpg";
import paredGruesaImgUrl from "../../assets/tuberia-conduit-pared-gruesa.jpg";
import acometidaImgUrl from "../../assets/tuberia-conduit-conduit-acometida.jpg";
import cedula40ImgUrl from "../../assets/tuberia-conduit-cedula-40.jpg";
import codoParedDelgadaImgUrl from "../../assets/tuberia-conduit-codo-pared-delgada.jpg";
import codoParedGruesaImgUrl from "../../assets/tuberia-conduit-codo-pared-gruesa.jpg";
import copleDelgadoImgUrl from "../../assets/tuberia-conduit-copledelgadoagrueso.jpg";
import copleDelGruesoImgUrl from "../../assets/tuberia-conduit-cople-pared-delgada.jpg";
import copleGruesoImgUrl from "../../assets/tuberia-conduit-cople-pared-gruesa.jpg";

// Slider media
import firstSlideImgUrl from "../../assets/img-carousel-landing-3.jpg";
import secondSlideImgUrl from "../../assets/img-tuberia.jpg";
import thirdSlideImgUrl from "../../assets/img-tuberia-04.jpg";
import fourthSlideImgUrl from "../../assets/img-tuberia-03.jpg";
// import fifthSlideImgUrl from "../../assets/tuberia.jpeg";

const benefits = [
  {
    id: 1,
    image: benefit1IconUrl,
    text: "Requerimientos especiales",
  },
  {
    id: 2,
    image: benefit5IconUrl,
    text: "Atención personalizada",
  },
  {
    id: 3,
    image: benefit2IconUrl,
    text: "Entrega a tiempo",
  },
  {
    id: 4,
    image: benefit6IconUrl,
    text: "Proveedor confiable",
  },
  {
    id: 5,
    image: benefit3IconUrl,
    text: "Producto a la medida",
  },
  {
    id: 6,
    image: benefit7IconUrl,
    text: "Inventario de producto",
  },
  {
    id: 7,
    image: benefit4IconUrl,
    text: "Calidad del producto",
  },
  {
    id: 8,
    image: benefit8IconUrl,
    text: "Cobertura de distribución nacional",
  },
];

const branchesArray = [
  {
    imgUrl: agsImgUrl,
    cityName: "Aguascalientes",
    branchPhone: "4491464235",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Carretera a San Luis Potosí km 2.0, Fracc. Casa Sólida, Aguascalientes, Aguascalientes. C.P. 20169",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox+Aguascalientes/@21.861506,-102.225083,17z/data=!4m5!3m4!1s0x0:0x387290c2ade98517!8m2!3d21.8615063!4d-102.2250835?hl=es-419",
  },
  {
    imgUrl: chihImgUrl,
    cityName: "Chihuahua",
    branchPhone: "6143070246",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Alejandro Dumas No. 11130, No .Int 10 y 11, Complejo Industrial Chihuahua, Chihuahua, Chihuahua, C.P. 31136",
    branchLocationLink:
      "https://www.google.com/maps?ll=28.729956,-106.11388&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=2030636383931288465",
  },
  {
    imgUrl: clcImgUrl,
    cityName: "Culiacán",
    branchPhone: "6671705198",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Libramiento Sur Benito Juárez No. 6301, Int. 34ª Poniente, Col. Parque Industrial la costa, Culiacán Rosales, Culiacán, Sinaloa, C.P. 80140",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox/@24.748713,-107.457904,17z/data=!4m5!3m4!1s0x0:0xb43a5ee1ce281ebd!8m2!3d24.7487126!4d-107.457904?hl=es-419",
  },
  {
    imgUrl: gdlImgUrl,
    cityName: "Guadalajara",
    branchPhone: "3333444636",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Patria No. 29, Col. El Manantial, Guadalajara, Jalisco, C.P. 44990",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.622249,-103.345902&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=17318830583385505820",
  },
  {
    imgUrl: iztaImgUrl,
    cityName: "Iztapalapa",
    branchPhone: "5555815896",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. 5 de Mayo No. 9, Local E, Colonia Ampliación los Reyes Culhuacán, Delegación Iztapalapa, C.P. 09849,Ciudad de México, México",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.343021,-99.105949&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=1610868234904062615",
  },
  {
    imgUrl: leonImgUrl,
    cityName: "León",
    branchPhone: "4777776405",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Blvd. Alonso de Torres, No. 231-A, Col. Las Hilamas, León, Guanajuato, C.P. 37353",
    branchLocationLink:
      "https://www.google.com/maps?ll=21.123541,-101.720131&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3260945479459544977",
  },
  {
    imgUrl: mazatImgUrl,
    cityName: "Mazatlán",
    branchPhone: "6691332595",
    branchSecondaryPhone: "6691332592",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Francisco Gonzalez Bocanegra No. 8900, Col. El Conchi II, Mazatlán, Sinaloa,C.P. 82134",
    branchLocationLink:
      "https://www.google.com/maps?ll=23.272148,-106.40246&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=12985841781860867956",
  },
  {
    imgUrl: montImgUrl,
    cityName: "Mérida",
    branchPhone: "9992522233",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Periférico poniente KM 41+700, No. 3, Fraccionamiento Juan Pablo II, Mérida, Yucatán, C.P. 97246",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.982791,-89.680539&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=14570237733386454061",
  },
  {
    imgUrl: montImgUrl,
    cityName: "Monterrey",
    branchPhone: "8181440010",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "América del Norte 208, Col. Parque Industrial Las Américas",
    branchLocationLink:
      "https://www.google.com/maps?ll=25.699747,-100.241753&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3198052743347169609",
  },
  {
    imgUrl: moreImgUrl,
    cityName: "Morelia",
    branchPhone: "4433230424",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "General Jose Aceves, No. 36, Col. Elías Pérez Avalos, Morelia, Michoacán de Ocampo,C.P. 58218",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.711324,-101.165418&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=5907667291632038982",
  },
  {
    imgUrl: pueImgUrl,
    cityName: "Puebla",
    branchPhone: "2222330000",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Calle: Río Lerma No. 10 Bodega 3, Colonia: San Lorenzo Almecatla, Cuautlancingo, Puebla, C.P.72730",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.118929,-98.257698&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=8138320865941269881",
  },
  {
    imgUrl: quereImgUrl,
    cityName: "Querétaro",
    branchPhone: "4422145914",
    branchSecondaryPhone: "4422175191",
    branchThirdPhone: "4422177766",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Camino a Campo Militar No. 25 (KM 2.65), Nave A-4, Col. La Sierrita, Santiago de Querétaro, Querétaro, Querétaro, CP. 76137",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.594751,-100.418403&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=4052025410070731133",
  },
  // {
  //   imgUrl: reynImgUrl,
  //   cityName: "Reynosa",
  //   branchPhone: "8999256505",
  //   branchSchedule:
  //     "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
  //   branchLocation:
  //     "Av. Rodolfo Garza Cantú, No. 102, Col. Las Mitras, Reynosa, Tamaulipas, C.P. 88759",
  //   branchLocationLink:
  //     "https://www.google.com/maps?ll=26.04706,-98.300278&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15870023934054905851",
  // },
  // {
  //   imgUrl: saltiImgUrl,
  //   cityName: "Saltillo",
  //   branchPhone: "8442779576",
  //   branchSchedule:
  //     "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
  //   branchLocation:
  //     "Blvd. Vito Alessio Robles 5625, Asturias. Saltillo, Coahuila, C.P. 25107",
  //   branchLocationLink:
  //     "https://www.google.com/maps/place/Prominox/@25.478969,-100.991305,17z/data=!4m5!3m4!1s0x0:0x5bcb75062832147a!8m2!3d25.4789685!4d-100.9913047?hl=es-419",
  // },
  {
    imgUrl: slpImgUrl,
    cityName: "San Luis Potosí",
    branchPhone: "4448161007",
    branchSecondaryPhone: "4448096454",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Carretera Rio Verde No. 2260, Col. Genovevo Rivas Guillen Gral.,Soledad de Graciano Sanchez, San Luis Potosí, C.P. 78436",
    branchLocationLink:
      "https://www.google.com/maps?ll=22.1533,-100.935828&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=10168027290383277582",
  },
  {
    imgUrl: reynImgUrl,
    cityName: "Tampico",
    branchPhone: "8331000142",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "6A Avenida 317, Villa Hermosa, Tampico, Tamaulipas C.P. 89319 ",
    branchLocationLink:
      "https://www.google.com/maps?ll=22.323001,-97.86955&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15835830103075095847",
    secondImgUrl: tmpSecondImgUrl,
  },
  {
    imgUrl: tijImgUrl,
    cityName: "Tijuana",
    branchPhone: "6649695548",
    branchSecondaryPhone: "6649695576",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Vía rápida oriente, No. 15540, Rio Tijuana 3ra Etapa, Tijuana, Baja California, C.P. 22226",
    branchLocationLink:
      "https://www.google.com/maps?ll=32.496169,-116.941583&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16608062537878815893",
  },
  {
    imgUrl: tlalnImgUrl,
    cityName: "Tlalnepantla",
    branchPhone: "5550050580",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm. ",
    branchLocation:
      "Henry Ford No. 4, No. Int. 3, Col. Industrial San Nicolas, Tlalnepantla, Edo de México, C.P. 54030",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox/@19.552802,-99.204649,17z/data=!4m5!3m4!1s0x0:0x3071f6b979be0655!8m2!3d19.5528017!4d-99.2046493?hl=es-419",
  },
  {
    imgUrl: torrnImgUrl,
    cityName: "Torreón",
    branchPhone: "8717187836",
    branchSecondaryPhone: "8717187293",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Blvd. Torreón Matamoros, No. 7691 Ote, No. Int. Manzana 57, Lote 8-C, Col. Oscar Flores Tapia, Torreón, Coahuila de Zaragoza, C.P. 27086",
    branchLocationLink:
      "https://www.google.com/maps?ll=25.535083,-103.34414&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16628314242009464813",
  },
  {
    imgUrl: vcrzImgUrl,
    cityName: "Veracruz",
    branchPhone: "2299208405",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Sin nombre #94 A un costado de Velatorio la Luz Frente a la Laguna Malibrán, Floresta, 91940 Veracruz, Ver. C.P. 91940",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox+Veracruz+%7C+Acero+Inoxidable+%7C+Aluminio+%7C+Tuber%C3%ADa+Conduit/@19.1616498,-96.1385608,17z/data=!3m1!4b1!4m5!3m4!1s0x85c343522405ab89:0x87c23a38ef8c9132!8m2!3d19.1616633!4d-96.1363799",
  },
];

export default function Tuberia() {
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Tuberia Conduit</title>
        <meta
          name="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta
          name="keywords"
          content="Tuberia Conduit, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Tuberia Conduit" />
        <meta
          itemprop="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Prominox | Accesorios para barandales"
        />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Prominox | Accesorios para barandales"
        />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-landing">
          <video
            autoPlay
            loop
            muted
            className="video"
            // style={{ objectFit: "cover" }}
          >
            <source src={VideoIntro} type="video/mp4" />
          </video>
          <Container>
            <Row>
              <Col xs={12} lg={6}>
                <img src={prominoxLogoUrl} alt="Prominox " />
              </Col>
              <Col xs={12} lg={6}>
                <h1 className="wow fadeInUp">tuberia conduit</h1>
                <img
                  src={jupiterImgUrl}
                  alt="Prominox"
                  className="wow fadeInUp"
                  data-wow-delay=".3s"
                />
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </section>
      <section className="about">
        <Container fluid>
          <Row>
            <Col xs={12} lg={6}>
              <div className="about__heading wow fadeInUp">
                <div>
                  <h1>Somos</h1>
                  <h2>Grupo</h2>
                  <h3>Industrial</h3>
                  <h4>Mexicano</h4>
                </div>
                <img src={gimLogo8Url} alt="Prominox" />
              </div>
              <Row className="about__group">
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center"
                >
                  <img src={logoPeasa2} alt="Prominox" />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center"
                >
                  <img src={logoPeasa8} alt="Prominox" />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center mt-3 mt-md-0"
                >
                  <img
                    src={logoProminox}
                    style={{ height: "80%" }}
                    alt="Prominox"
                  />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center mt-3 mt-md-0"
                >
                  <img src={logoGimtrac} alt="Prominox" />
                </Col>
              </Row>
              <Row className="about__certifications">
                <Col xs={6} lg={4}>
                  <img src={logoIntertek} alt="Prominox" />
                </Col>
                <Col xs={6} lg={8} className="d-flex align-items-md-end">
                  <h1>NUESTRAS CERTIFICACIONES</h1>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="about__benefits wow fadeInDown"
              data-wow-delay=".3s"
            >
              <h2>¿Por qué comprar en Prominox?</h2>
              <Row>
                {benefits.map((benefit) => (
                  <Col
                    xs={12}
                    md={6}
                    className="d-flex align-items-center mt-3"
                  >
                    <img src={benefit.image} alt="" />
                    <p>{benefit.text}</p>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-2">
        <Container fluid className="h-100">
          <Row className="h-100">
            <Col xs={12} md={6} className="about-2__tuberia-conduit">
              <h1 className="wow fadeInUp">tuberia conduit</h1>
              <div
                className="about-2__tuberia-conduit__list wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div>
                  <img src={tuberiaIconUrl} alt="Prominox" />
                  <h3>Acero de primer calidad</h3>
                </div>
                <div>
                  <img src={tuberiaIconUrl} alt="Prominox" />
                  <h3>Rolado en frío y/o caliente</h3>
                </div>
                <div>
                  <img src={tuberiaIconUrl} alt="Prominox" />
                  <h3>
                    Galvanizado exterior con mayor duración a la corrosión
                  </h3>
                </div>
                <div>
                  <img src={tuberiaIconUrl} alt="Prominox" />
                  <h3>Acabado de galvanizado y liso</h3>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} className="about-2__certifications">
              <h2 className="wow fadeInUp">nuestras</h2>
              <h1 className="wow fadeInUp">certificaciones</h1>
              <Row className="wow fadeInUp" data-wow-delay=".3s">
                <Col xs={6}>
                  <img src={logoCFE} alt="Prominox" style={{ height: "70%" }} />
                </Col>
                <Col xs={6}>
                  <img src={logoIMSS} alt="Prominox" />
                </Col>
                <Col xs={6} className="mt-2">
                  <img src={logoAnce} alt="Prominox" />
                </Col>
                <Col xs={6} className="mt-2">
                  <img src={logoUl} alt="Prominox" />
                </Col>
                <Col xs={6} className="mt-2">
                  <img src={logoLapem} alt="Prominox" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="accesories-lineup--tuberia">
        <Container>
          <h2 className="wow fadeInUp">línea de productos</h2>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3}>
              <img
                src={paredExDelgadaImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3}>
              <img src={paredDelgadaImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mt-2">
              <img src={paredGruesaImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mt-2">
              <img src={acometidaImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mt-2 mt-md-4">
              <img src={cedula40ImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mt-2 mt-md-4">
              <img
                src={codoParedDelgadaImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mt-2 mt-md-4">
              <img
                src={codoParedGruesaImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mt-2 mt-md-4">
              <img src={copleDelgadoImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={3} className="mt-2 mt-md-4">
              <img
                src={copleDelGruesoImgUrl}
                alt="Accesorios para barandales"
              />
            </Col>
            <Col xs={6} md={3} className="mt-2 mt-md-4">
              <img src={copleGruesoImgUrl} alt="Accesorios para barandales" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="branches">
        <Container>
          <h1 className="wow fadeInUp">20 sucursales en méxico</h1>
          <CardDeck className="wow fadeInUp" data-wow-delay=".3s">
            {branchesArray.map(
              ({
                imgUrl,
                cityName,
                branchPhone,
                branchSecondaryPhone,
                branchSchedule,
                branchLocation,
                branchLocationLink,
                secondImgUrl,
              }) => (
                <BranchCard
                  imgUrl={imgUrl}
                  cityName={cityName}
                  branchPhone={branchPhone}
                  branchSecondaryPhone={branchSecondaryPhone}
                  branchSchedule={branchSchedule}
                  branchLocation={branchLocation}
                  branchLocationLink={branchLocationLink}
                  secondImgUrl={secondImgUrl}
                />
              )
            )}
          </CardDeck>
        </Container>
      </section>
      <section className="carousel--tuberia-container">
        <div className="carousel--tuberia-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>
        <div
          className="carousel--tuberia-caption--bottom wow fadeInUp"
          data-wow-delay=".3s"
        >
          <p>
            Fabricado bajo las normas ASTM-A-569, ASTM-A-366, ASTM-B6 y con
            acero de alta calidad y sometido a un proceso de galvanizado
          </p>
        </div>
        <Carousel interval={null} className="mb-0">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={firstSlideImgUrl}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={secondSlideImgUrl}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={thirdSlideImgUrl}
              alt="Third slide"
            />
            {/* <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={fourthSlideImgUrl}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
      </section>
      <Contact />
    </div>
  );
}
