import React from "react";
import { useParams } from "react-router";

// Custom CSS
import "./submission.styles.scss";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Assets
import HomeVideo from "../../assets/videos/video-post-formulario.mp4";

export default function Submission() {
  let { id } = useParams();
  // console.log(id);
  return (
    <div className="thank-you">
      <video autoPlay loop muted className="video">
        <source src={HomeVideo} type="video/mp4" />
      </video>
      <Container>
        <Row>
          <Col xs="12" lg="6" className="text-lg-right">
            <h1>formulario recibido</h1>
          </Col>
          <Col xs="12" lg="6" className="d-flex align-items-center">
            <p>En un momento uno de nuestros ejecutivos lo contactará.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
