import React, { useState, useEffect } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

import { Helmet } from "react-helmet";

import "./accesories.styles.scss";

import barandalIconUrl from "../../assets/barandal-icon--white.png";
import bridasImgUrl from "../../assets/barandal-bridas.jpg";
import jaladerasImgUrl from "../../assets/barandal-jaladeras.jpg";
import conectoresImgUrl from "../../assets/barandal-conectores.jpg";
import soportesImgUrl from "../../assets/barandal-soportes.jpg";
import tapasImgUrl from "../../assets/barandal-tapas.jpg";
import ledImgUrl from "../../assets/barandal-led.jpg";
import firstSlideImgUrl from "../../assets/accesorios-barandales-slide-1.png";
import secondSlideImgUrl from "../../assets/accesorios-barandales-slide-2.png";
import thirdSlideImgUrl from "../../assets/accesorios-barandales-slide-3.png";
import fourthSlideImgUrl from "../../assets/accesorios-barandales-slide-4.jpg";
import popUp from "../../assets/popUp.jpeg";
import Contact from "../Contact/Contact";

import AccesoriesVideo from "../../assets/videos/ACC-BARANDALES.mp4";

export default function Accesories() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Accesorios para barandales</title>
        <meta
          name="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta
          name="keywords"
          content="accesorios para barandales, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Accesorios para barandales" />
        <meta
          itemprop="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Prominox | Accesorios para barandales"
        />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Prominox | Accesorios para barandales"
        />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-accesories">
          <video
            autoPlay
            loop
            muted
            className="video"
            // style={{ objectFit: "cover" }}
          >
            <source src={AccesoriesVideo} type="video/mp4" />
          </video>
          <Container>
            <h1 className="wow fadeInUp">accesorios para barandales</h1>
            <div
              className="jumbotron--hero__cards__container wow fadeInUp"
              data-wow-delay=".3s"
            >
              <Row className="justify-content-md-center">
                <Col xs={12} lg={4} className="jumbotron--hero__card">
                  <img src={barandalIconUrl} alt="" />
                  <p>Fácil instalación</p>
                </Col>
                <Col xs={12} lg={4} className="jumbotron--hero__card">
                  <img src={barandalIconUrl} alt="" />
                  <p>Gran durabilidad</p>
                </Col>
                <Col xs={12} lg={4} className="jumbotron--hero__card">
                  <img src={barandalIconUrl} alt="" />
                  <p>Elegantes</p>
                </Col>
                <Col xs={12} lg={4} className="jumbotron--hero__card">
                  <img src={barandalIconUrl} alt="" />
                  <p>Seguridad</p>
                </Col>
                <Col xs={12} lg={4} className="jumbotron--hero__card">
                  <img src={barandalIconUrl} alt="" />
                  <p>Fácil mantenimiento</p>
                </Col>
              </Row>
            </div>
          </Container>
        </Jumbotron>
      </section>
      <div className="accesories-lineup">
        <Container>
          <h2 className="wow fadeInUp">línea de productos</h2>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={4} className="text-lg-center">
              <img src={bridasImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4} className="text-lg-center">
              <img
                src={jaladerasImgUrl}
                alt="Accesorios para barandales"
                className="ml-3 ml-lg-0"
              />
            </Col>
            <Col xs={6} md={4} className="text-lg-center">
              <img
                src={conectoresImgUrl}
                alt="Accesorios para barandales"
                className="mr-lg-3"
              />
            </Col>
            <Col xs={6} md={4} className="mt-md-4 text-center">
              <img src={soportesImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4} className="mt-md-4 text-left text-lg-center">
              <img src={tapasImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4} className="mt-md-4 text-center">
              <img
                src={ledImgUrl}
                alt="Accesorios para barandales"
                className="ml-2 ml-lg-0"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="catalogue">
        <Container>
          <h3 className="wow fadeInUp">catálogo</h3>
          <iframe
            title="e-magazine"
            src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=f7hnannh6"
            width="100%"
            height="480"
            seamless="seamless"
            scrolling="no"
            frameBorder="0"
            allowFullScreen
            className="wow fadeInUp"
          ></iframe>
        </Container>
      </div>
      <div className="carousel--accesories-container">
        <div className="carousel--accesories-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>
        <div className="carousel--accesories-caption--bottom">
          <p className="wow fadeInUp" data-wow-delay=".3s">
            Aplicacion en casa-habitación.
          </p>
        </div>
        <Carousel interval={null} className="mb-0">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={firstSlideImgUrl}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={secondSlideImgUrl}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={thirdSlideImgUrl}
              alt="Third slide"
            />
            {/* <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={fourthSlideImgUrl}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <Contact />
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
