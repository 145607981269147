import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import "./contact.styles.scss";

import axios from "axios";
import { Formik } from "formik";

// Firebase
// import { db } from "../../firebase";

// const accesorios = [
//   {
//     city: "Campeche",
//     mail: "mariana.flores@prominox.com.mx",
//   },
//   {
//     city: "Chiapas",
//     mail: "marisol.cantellano@prominox.com.mx",
//   },
//   {
//     city: "Ciudad de México",
//     mail: "marisol.cantellano@prominox.com.mx",
//   },
// ];

// const aluminio = [
//   {
//     city: "Campeche",
//     mail: "alfredo.lopez@prominox.mx",
//   },
//   {
//     city: "Chiapas",
//     mail: "alfredo.lopez@prominox.mx",
//   },
//   {
//     city: "Ciudad de México",
//     mail: "alfredo.lopez@prominox.mx",
//   },
// ];

const productsFinalArray = {
  accesorios: [
    {
      city: "Aguascalientes",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Baja California Sur",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Campeche",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Colima",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Durango",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "mariana.flores@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "mariana.flores@prominox.mx",
    },
  ],
  aluminio: [
    {
      city: "Aguascalientes",
      // mail: "erwin.ayvar@prominox.mx",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "norma.miramontes@prominox.mx",
      // mail: "roxanna@marketingmaximo.com.mx",
    },
    {
      city: "Baja California Sur",
      mail: "norma.miramontes@prominox.mx",
      // mail: "ashanti.escamilla@marketingmaximo.com.mx",
    },
    {
      city: "Campeche",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "norma.miramontes@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "norma.miramontes@prominox.mx",
    },
    {
      city: "Colima",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Durango",
      mail: "norma.miramontes@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "norma.miramontes@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "manuel.mendoza@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "norma.miramontes@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "alfredo.lopez@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "manuel.mendoza@prominox.mx",
    },
  ],
  acero: [
    {
      city: "Aguascalientes",
      mail: "ricardo.vazquez@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "douglas.kittrell@prominox.mx",
    },
    {
      city: "Baja California Sur",
      mail: "julio.osuna@prominox.mx",
    },
    {
      city: "Campeche",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "victor.villalobos@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "javier.cano@prominox.mx",
    },
    {
      city: "Colima",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Durango",
      mail: "alondra.sotomayor@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "jose.munoz@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "jonathan.mendez@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "nelly.alvarez@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "hilda.sanchez@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "samuel.orozco@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "luis.ayala@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "douglas.kittrell@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "ximena.martinez@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "ricardo.vazquez@prominox.mx",
    },
  ],
  tuberia: [
    {
      city: "Aguascalientes",
      mail: "ricardo.vazquez@prominox.mx",
    },
    {
      city: "Baja California",
      mail: "marco.meza@prominox.mx",
    },
    {
      city: "Baja California Sur",
      mail: "julio.osuna@prominox.mx",
    },
    {
      city: "Campeche",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "CDMX",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Chiapas",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Chihuahua",
      mail: "carlos.samaniego@prominox.mx",
    },
    {
      city: "Coahuila",
      mail: "javier.cano@prominox.mx",
    },
    {
      city: "Colima",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Durango",
      mail: "alondra.sotomayor@prominox.mx",
    },
    {
      city: "Estado de México",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Guanajuato",
      mail: "jose.munoz@prominox.mx",
    },
    {
      city: "Guerrero",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Hidalgo",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Jalisco",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Michoacán",
      mail: "jonathan.mendez@prominox.mx",
    },
    {
      city: "Morelos",
      mail: "ramon.martinez@prominox.mx",
    },
    {
      city: "Nayarit",
      mail: "jonathan.tapia@prominox.mx",
    },
    {
      city: "Nuevo León",
      mail: "nelly.alvarez@prominox.mx",
    },
    {
      city: "Oaxaca",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Puebla",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Quéretaro",
      mail: "hilda.sanchez@prominox.mx",
    },
    {
      city: "Quintana Roo",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "San Luis Potosí",
      mail: "samuel.orozco@prominox.mx",
    },
    {
      city: "Sinaloa",
      mail: "luis.ayala@prominox.mx",
    },
    {
      city: "Sonora",
      mail: "marco.meza@prominox.mx",
    },
    {
      city: "Tabasco",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "Tamaulipas",
      mail: "maria.alejandre@prominox.mx, ximena.martinez@prominox.mx",
    },
    {
      city: "Tlaxcala",
      mail: "ernesto.paniagua@prominox.mx",
    },
    {
      city: "Veracruz",
      mail: "susana.ramirez@prominox.mx",
    },
    {
      city: "Yucatán",
      mail: "alejandro.mendez@prominox.mx",
    },
    {
      city: "Zacatecas",
      mail: "ricardo.vazquez@prominox.mx",
    },
  ],
};

const products2Array = {
  accesorios: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
  aluminio: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
  acero: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
  tuberia: [
    {
      city: "Aguascalientes",
      mail: "rzentenom@quintech.com.mx",
    },
    {
      city: "Baja California",
      mail: "rodrigo.zenteno7@gmail.com",
    },
    {
      city: "Baja California Sur",
      mail: "contact@quintech.com.mx",
    },
  ],
};
export default function Contact() {
  // const [inputs, setInputs] = useState();
  const [product, setProduct] = useState();
  const [error, setError] = useState("");
  const [city, setCity] = useState();
  const [sendTo, setSendTo] = useState();
  const history = useHistory();
  useEffect(() => {
    if (currentCity.length > 0) {
      const { mail } = currentCity[0];
      setSendTo(mail);
    }
  }, [city]);
  // function handleChange(e) {
  //   let { value, name } = e.target;
  //   setInputs({
  //     ...inputs,
  //     [name]: value,
  //   });
  // }
  // function handleProductChange(val) {
  //   // let { value, name } = e.target;
  //   setProduct(val);
  //   value = val;
  // }
  function handleCityChange(e) {
    let { value, name } = e.target;
    setCity(value);
  }
  const selectedArray = [];
  const citiesArray = [];
  // if (product === "accesorios") {
  //   console.log(productsArray.accesorios);
  // }
  switch (product) {
    case "accesorios-para-barandales":
      productsFinalArray.accesorios.map((product) =>
        selectedArray.push(product)
      );
      selectedArray.map((sel) => citiesArray.push(sel.city));
      break;
    case "aluminio":
      productsFinalArray.aluminio.map((product) => selectedArray.push(product));
      selectedArray.map((sel) => citiesArray.push(sel.city));
      break;
    case "acero-inoxidable":
      productsFinalArray.acero.map((product) => selectedArray.push(product));
      selectedArray.map((sel) => citiesArray.push(sel.city));
      break;
    case "tubería-conduit":
      productsFinalArray.tuberia.map((product) => selectedArray.push(product));
      selectedArray.map((sel) => citiesArray.push(sel.city));
      break;

    default:
      break;
  }
  // console.log(selectedArray);
  // console.log(citiesArray);
  const currentCity = selectedArray.filter((m) => m.city === city);
  // console.log(currentCity[0]);
  // setSendTo(currentCity[0]);
  // console.log(sendTo);
  // console.log(sendTo);
  // if (currentCity.length > 0) {
  //   const { mail } = currentCity[0];
  //   setSendTo(mail);
  //   console.log(mail);
  // }

  // function onSubmit(e) {
  //   const { clientEmail, clientName, clientPhone, clientMessage } = inputs;
  //   e.preventDefault();
  //   // console.log(city);
  //   // console.log(inputs);
  //   axios
  //     .post("http://prominox.com.mx/api/email", {
  //       // .post("http://localhost:1337/email", {
  //       to: [
  //         `${sendTo}`,
  //         "erwin.ayvar@prominox.mx",
  //         "daniela.patino@prominox.mx",
  //         "rodrigo.zenteno7@gmail.com",
  //         "ashanti@marketingmaximo.com.mx",
  //       ],
  //       // to: "rodrigo.zenteno7@gmail.com",
  //       subject: "Nuevo mensaje de forma de contacto en Prominox",
  //       html: `<h1>${clientName} envió un mensaje </h1> <p>Estos son sus datos de contacto:</p> <ul><li>Correo de contacto: ${clientEmail}</li><li>Teléfono: ${clientPhone}</li><li>Está interesado/a en: ${product}</li><li>Ciudad: ${city}</li></ul><p>Mensaje: ${clientMessage}</p>`,
  //       // html: `<html lang="es"><head><meta charset="UTF-8" /><meta http-equiv="X-UA-Compatible" content="IE=edge" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><link rel="preconnect" href="https://fonts.gstatic.com" /><link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Raleway&display=swap" rel="stylesheet"/></head><body style="margin: 0; padding: 0; background-color: #ddd; color: #000;"><div style="font-family: 'Raleway', sans-serif; background-color: #ddd; padding: 1rem; display: flex; justify-content: center; height: 100%;"> <div style="width: 80%; height: 400px; margin: auto; background-color: #fff; border-bottom: 15px solid #6D1E39; padding: 2.5rem;"><h2 style="text-transform: uppercase; font-family: 'Montserrat', sans-serif; font-weight: 600; margin-bottom: 1rem;">${formData.clientName} envió un mensaje</h2><p style="margin-bottom: 1rem">Estos son sus datos de contacto:</p><ul><li>Correo de contacto: ${formData.email}</li><li>Teléfono: ${formData.phone}</li><li>Está interesado/a en: ${data.NombrePropiedad}</li></ul><img src="https://clusterdelcentro.com/logo.png" alt="Logo Cluster del Centro" style="display: block; margin: 0 auto; width: 120px; height: auto;"/></div></div></body></html>`,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   // db.collection("mail")
  //   //   .add({
  //   //     to: `${sendTo}`,
  //   //     message: {
  //   //       subject: "Nuevo mensaje de página web PROMINOX",
  //   //       html: `<h1>${clientName} envió un mensaje</h1> <p>Estos son sus datos de contacto:</p> <ul><li>Correo de contacto: ${clientEmail}</li><li>Teléfono: ${clientPhone}</li></ul> <p>Está interesada/o en: ${product} </p> <p>Mensaje: ${clientMessage}</p>`,
  //   //     },
  //   //   })
  //   //   .then(() => console.log("Queued email for delivery!"));
  //   e.target.reset();
  // }
  // if (product === "accesorios") {
  //   setCity(accesorios);
  //   console.log(city);
  // } else {
  //   console.log("hola");
  // }
  return (
    <section id="contact" className="contact">
      <Container>
        <h2 className="wow fadeInDown">solicita tu cotización</h2>
        <div
          className="contact__form__container wow fadeInDown"
          data-wow-delay=".5s"
        >
          <Formik
            initialValues={{
              clientName: "",
              clientEmail: "",
              product: "",
              city: "",
              clientPhone: 0,
              clientMessage: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.clientEmail) {
                errors.clientEmail = "Correo electrónico es un campo requerido";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.clientEmail
                )
              ) {
                errors.clientEmail = "Invalid email address";
              }
              if (!values.product) {
                errors.product = "Producto es un campo requerido";
              }
              if (!values.city) {
                errors.city = "Ciudad es un campo requerido";
              }
              if (!values.clientName) {
                errors.clientName = "Nombre completo es un campo requerido";
              }
              if (values.clientPhone === 0) {
                errors.clientPhone = "Teléfono es un campo requerido";
              }
              if (!values.clientMessage) {
                errors.clientMessage = "Mensaje es un campo requerido";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log(values);
              const {
                city,
                clientEmail,
                clientMessage,
                clientName,
                clientPhone,
                product,
              } = values;
              axios
                .post("https://prominox.com.mx/api/email", {
                  // .post("http://localhost:1337/email", {
                  to: [
                    `${sendTo}`,
                    "erwin.ayvar@prominox.mx",
                    "paola.uribe@prominox.mx",
                    // "daniela.patino@prominox.mx",
                    // "ruben@marketingmaximo.com.mx",
                    // "rodrigo.zenteno7@gmail.com",
                    // "ashanti@marketingmaximo.com.mx",
                    // "contact@quintech.com.mx",
                  ],
                  subject: "Nuevo mensaje de forma de contacto en Prominox",
                  html: `<h1>${clientName} envió un mensaje </h1> <p>Estos son sus datos de contacto:</p> <ul><li>Correo de contacto: ${clientEmail}</li><li>Teléfono: ${clientPhone}</li><li>Está interesado/a en: ${product}</li><li>Ciudad: ${city}</li></ul><p>Mensaje: ${clientMessage.replace(
                    /[\n]/g,
                    "<br>"
                  )}</p>`,
                })
                .then((res) => {
                  history.push(`/gracias-${product}`);
                })
                .catch(function (error) {
                  console.log(error);
                  setError(
                    "Hubo un error en el envío de tu cotización. Por favor inténtalo de nuevo o comunícate con nosotros."
                  );
                  setTimeout(() => {
                    setError("");
                  }, 5000);
                });
              resetForm({
                clientName: "",
                clientEmail: "",
                product: "",
                city: "",
                clientPhone: 0,
                clientMessage: "",
              });
              setCity("");
              setProduct("");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} id="contact-form">
                {error !== "" && <p className="contact-form__error">{error}</p>}
                <Form.Group controlId="formGroupName">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control
                    type="text"
                    name="clientName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientName}
                  />
                  <span>
                    {errors.clientName &&
                      touched.clientName &&
                      errors.clientName}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupEmail">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    name="clientEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientEmail}
                  />
                  <span>
                    {errors.clientEmail &&
                      touched.clientEmail &&
                      errors.clientEmail}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupPhone">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="number"
                    name="clientPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientPhone}
                  />
                  <span>
                    {errors.clientPhone &&
                      touched.clientPhone &&
                      errors.clientPhone}
                  </span>
                </Form.Group>
                <Form.Group controlId="formGroupProduct">
                  <Form.Label>¿Qué producto buscas?</Form.Label>
                  <Form.Control
                    as="select"
                    name="product"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setProduct(e.target.value);
                      values.product = e.target.value;
                    }}
                    onBlur={handleBlur}
                    value={product}
                  >
                    <option>Seleccionar uno...</option>
                    <option value="accesorios-para-barandales">
                      Accesorios para barandales
                    </option>
                    <option value="acero-inoxidable">Acero inoxidable</option>
                    <option value="aluminio">Aluminio</option>
                    <option value="tubería-conduit">Tubería Conduit</option>
                  </Form.Control>
                  <span>
                    {errors.product && touched.product && errors.product}
                  </span>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>¿Donde te encuentras?</Form.Label>
                  <Form.Control
                    as="select"
                    name="city"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setCity(e.target.value);
                      values.city = e.target.value;
                    }}
                    onBlur={handleBlur}
                    value={city}
                    required
                  >
                    <option>Seleccionar estado...</option>
                    {citiesArray.map((city, key) => (
                      <option value={`${city}`} key={key}>
                        {city}
                      </option>
                    ))}
                  </Form.Control>
                  <span>{errors.city && touched.city && errors.city}</span>
                </Form.Group>
                <Form.Group controlId="formGroupPhone">
                  <Form.Label>
                    Información adicional de su cotización
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="clientMessage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientMessage}
                  />
                  <span>
                    {errors.clientMessage &&
                      touched.clientMessage &&
                      errors.clientMessage}
                  </span>
                </Form.Group>
                <button type="submit" className="form-send">
                  Enviar
                </button>
              </Form>
              //  <form onSubmit={handleSubmit}>
              //    <input
              //      type="email"
              //      name="email"
              //      onChange={handleChange}
              //      onBlur={handleBlur}
              //      value={values.email}
              //    />
              //    {errors.email && touched.email && errors.email}
              //    <input
              //      type="password"
              //      name="password"
              //      onChange={handleChange}
              //      onBlur={handleBlur}
              //      value={values.password}
              //    />
              //    {errors.password && touched.password && errors.password}
              //    <button type="submit" disabled={isSubmitting}>
              //      Submit
              //    </button>
              //  </form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}
