import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

// import Form from "react-bootstrap/Form";

import "./home.styles.scss";

import aceroUrl from "../../assets/img-home-acero.png";
import aluminioUrl from "../../assets/img-home-aluminio.png";
import tuberiaUrl from "../../assets/img-home-tuberia.png";
import barandalUrl from "../../assets/img-home-barandal.png";
import experienciaUrl from "../../assets/experiencia.svg";
// import experienciaUrl2 from "../../assets/experiencia.jpg";
import certificacionUrl from "../../assets/certificacion.svg";
import certificacionUrl2 from "../../assets/intertek.png";
import nacionalUrl from "../../assets/nacional.svg";
// import nacionalUrl2 from "../../assets/nacional.jpg";
import centroUrl from "../../assets/centro.svg";
// import centroUrl2 from "../../assets/centro.jpg";
import aceroProductoUrl from "../../assets/img-productos-acero.png";
import alumninioProductoUrl from "../../assets/img-producto-aluminio.png";
import tuberiaProductoUrl from "../../assets/img-producto-tuberia.png";
import barandalesProductoUrl from "../../assets/img-producto-barandal.png";
import arrowDownUrl from "../../assets/arrow_down-icon--white.svg";
import mapIconUrl from "../../assets/map-mexico.png";
import customIconUrl from "../../assets/custom-icon--white.svg";
import qualityIconUrl from "../../assets/quality-icon--white.svg";
import attentionIconUrl from "../../assets/attention-icon--white.svg";
import requirementIconUrl from "../../assets/requirements-icon--white.svg";
import trustIconUrl from "../../assets/trust-icon--white.svg";
import coverageIconUrl from "../../assets/coverage-icon--white.svg";
import deliverytIconUrl from "../../assets/delivery-icon--white.svg";
import inventoryIconUrl from "../../assets/inventory-icon--white.svg";
import autoIconUrl from "../../assets/auto-icon--white.png";
import planeIconUrl from "../../assets/plane-icon--white.png";
import buildingIconUrl from "../../assets/building-icon--white.png";
import kitchenIconUrl from "../../assets/kitchen-icon--white.png";
import transportIconUrl from "../../assets/transport-icon--white.png";
import homeIconUrl from "../../assets/home-icon--white.png";
import furnitureIconUrl from "../../assets/furniture-icon--white.png";
import homeElectroIconUrl from "../../assets/home-electro-icon--white.png";
import oilIconUrl from "../../assets/oil-icon--white.png";
import mineyIconUrl from "../../assets/minery-icon--white.png";
import gasIconUrl from "../../assets/gas-icon--white.png";
import electricIconUrl from "../../assets/electric-icon--white.png";
import prominoxUsaIso from "../../assets/prominox-usa-isotype.png";
import popUp from "../../assets/popUp.jpeg";

import Contact from "../Contact/Contact";

import HomeVideo from "../../assets/videos/HOME.mp4";

const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <div className="usa-float">
        <a
          href="https://www.prominox-usa.com/"
          target="_blank"
          rel="noopener noreferrer"
          // className="usa-float"
        >
          <img src={prominoxUsaIso} alt="Prominox USA" />
        </a>
        <p>Haz clic en el ícono para visitar Prominox USA</p>
      </div>
      <Jumbotron fluid className="jumbotron--hero">
        <video autoPlay loop muted className="video">
          <source src={HomeVideo} type="video/mp4" />
        </video>
        <Container>
          <h1 className="wow fadeInUp">prominox</h1>
          <h1 className="wow fadeInUp" data-wow-delay="1s">
            Somos una empresa orgullosamente mexicana líder en la
            comercialización de
          </h1>
          <Row className="wow fadeInUp" data-wow-delay="1.5s">
            <Col xs={6} lg={3} className="jumbotron--hero__products">
              <Link to="/acero-inoxidable">
                <img src={aceroUrl} alt="Producto 1 jumbotron" />
                <p>Acero inoxidable</p>
              </Link>
            </Col>
            <Col xs={6} lg={3} className="jumbotron--hero__products">
              <Link to="/aluminio">
                <img src={aluminioUrl} alt="Producto 1 jumbotron" />
                <p>Aluminio</p>
              </Link>
            </Col>
            <Col xs={6} lg={3} className="jumbotron--hero__products">
              <Link to="/tuberia">
                <img src={tuberiaUrl} alt="Producto 1 jumbotron" />
                <p>Tubería Conduit</p>
              </Link>
            </Col>
            <Col xs={6} lg={3} className="jumbotron--hero__products">
              <Link to="accesorios-barandales">
                <img src={barandalUrl} alt="Producto 1 jumbotron" />
                <p>Accesorios para barandales</p>
              </Link>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <section className="about-us">
        <Container>
          <h2 className="wow fadeInDown">¿quiénes somos?</h2>
          <Row className="wow fadeInUp" data-wow-delay=".6s">
            <Col xs={12} lg={6}>
              <div className="about-us__card">
                <img src={experienciaUrl} alt="Experiencia en el mercado" />
                <div className="about-us__card--body">
                  <h5>+30 años de experiencia</h5>
                  <p>
                    Prominox es una empresa líder en la distribución de acero
                    inoxidable, aluminio, tubería Conduit y accesorios para
                    barandales. Empresa mexicana fundada en 1989, la cual ha
                    crecido con solidez, innovación y excelencia en productos,
                    servicios y total compromiso con nuestros clientes.
                  </p>
                </div>
              </div>
              <div className="about-us__card">
                <img src={nacionalUrl} alt="Cobertura Nacional" />
                <div className="about-us__card--body">
                  <h5>cobertura nacional</h5>
                  <p>
                    Red de 18 sucursales ubicadas estratégicamente en las
                    ciudades más importantes a lo largo del país
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} className="mt-3 mt-md-0">
              <div className="about-us__card">
                <img
                  src={certificacionUrl2}
                  className="intertek"
                  alt="Certificación INTERTEK"
                />
                <div className="about-us__card--body">
                  <h5>certificación intertek</h5>
                </div>
              </div>
              <div className="about-us__card" style={{ marginTop: "2.3rem" }}>
                <img src={centroUrl} alt="Centros de servicio" />
                <div className="about-us__card--body">
                  <h5>centro de servicio</h5>
                  <p>Planta de 1er Nivel</p>
                  <p>Maquinaria de última generación</p>
                  <p>Líneas de producción</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="products">
        <Container>
          <h3 className="wow fadeInDown">nuestros productos</h3>
          <Row className="wow fadeInUp" data-wow-delay="1s">
            <Col xs={12} lg={3} className="mt-4 mt-md-0">
              <Link to="/acero-inoxidable" className="product__card">
                <img
                  src={aceroProductoUrl}
                  alt="Producto Prominox - Acero Inoxodable"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>acero inoxidable</h5>
              </Link>
            </Col>
            <Col xs={12} lg={3}>
              <Link to="/aluminio" className="product__card">
                <img
                  src={alumninioProductoUrl}
                  alt="Producto Prominox - Aluminio"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>aluminio</h5>
              </Link>
            </Col>
            <Col xs={12} lg={3}>
              <Link to="/tuberia" className="product__card">
                <img
                  src={tuberiaProductoUrl}
                  alt="Producto Prominox - Tubería Conduit"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>tubería conduit</h5>
              </Link>
            </Col>
            <Col xs={12} lg={3}>
              <Link to="/accesorios-barandales" className="product__card">
                <img
                  src={barandalesProductoUrl}
                  alt="Producto Prominox - Accesorios para Barandales"
                />
                <img src={arrowDownUrl} alt="Ícono Prominox" />
                <h5>accesorios para barandales</h5>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="coverage">
        <Container>
          <h4 className="wow fadeInDown">cobertura nacional</h4>
          <img
            className="wow fadeInUp"
            data-wow-delay=".5s"
            src={mapIconUrl}
            alt="PROMINOX cobertura nacional"
          />
          <div className="coverage__cities wow fadeInUp" data-wow-delay=".7s">
            <ul>
              <li>
                <a
                  href="https://www.google.com/maps/place/Prominox+Aguascalientes/@21.861506,-102.225083,17z/data=!4m5!3m4!1s0x0:0x387290c2ade98517!8m2!3d21.8615063!4d-102.2250835?hl=es-419"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aguascalientes
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=28.729956,-106.11388&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=2030636383931288465"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  chihuahua
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Prominox/@24.748713,-107.457904,17z/data=!4m5!3m4!1s0x0:0xb43a5ee1ce281ebd!8m2!3d24.7487126!4d-107.457904?hl=es-419"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  culiacán
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=20.622249,-103.345902&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=17318830583385505820"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  guadalajara
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=19.343021,-99.105949&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=1610868234904062615"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iztapalapa
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=21.123541,-101.720131&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3260945479459544977"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  león
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=23.272148,-106.40246&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=12985841781860867956"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  mazatlán
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=20.982791,-89.680539&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=14570237733386454061"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  mérida
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=25.699747,-100.241753&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3198052743347169609"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  monterrey
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=19.711324,-101.165418&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=5907667291632038982"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  morelia
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=19.118929,-98.257698&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=8138320865941269881"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  puebla
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=20.594751,-100.418403&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=4052025410070731133"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  querétaro
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=26.04706,-98.300278&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15870023934054905851"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  reynosa
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=22.1533,-100.935828&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=10168027290383277582"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  san luis potosí
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=32.496169,-116.941583&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16608062537878815893"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tijuana
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Prominox/@19.552802,-99.204649,17z/data=!4m5!3m4!1s0x0:0x3071f6b979be0655!8m2!3d19.5528017!4d-99.2046493?hl=es-419"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tlalnepantla
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps?ll=25.535083,-103.34414&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16628314242009464813"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  torreón
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Prominox+Veracruz+%7C+Acero+Inoxidable+%7C+Aluminio+%7C+Tuber%C3%ADa+Conduit/@19.1616498,-96.1385608,17z/data=!3m1!4b1!4m5!3m4!1s0x85c343522405ab89:0x87c23a38ef8c9132!8m2!3d19.1616633!4d-96.1363799"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  veracruz
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="why-us">
        <Container>
          <h5 className="wow fadeInDown">¿por qué comprar en prominox?</h5>
          <Row className="wow fadeInUp" data-wow-delay=".7s">
            <Col xs={12} lg={6}>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={customIconUrl} alt="Producto a la medida" />
                </div>
                <p>Producto a la medida</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={qualityIconUrl} alt="Calidad del producto" />
                </div>
                <p>Calidad del producto</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img src={attentionIconUrl} alt="Atención personalizada" />
                </div>
                <p>Atención personalizada</p>
              </div>
              <div className="why-us__individual">
                <div>
                  {" "}
                  <img
                    src={requirementIconUrl}
                    alt="Requerimientos especiales"
                  />
                </div>
                <p>Requerimientos especiales</p>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="why-us__individual mt-0 mt-lg-4">
                <div>
                  <img
                    src={coverageIconUrl}
                    alt="Cobertura de distribución nacional"
                  />
                </div>
                <p>Cobertura de distribución nacional</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={deliverytIconUrl} alt="Entrega a tiempo" />
                </div>
                <p>Entrega a tiempo</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={trustIconUrl} alt="Proveedor confiable" />
                </div>
                <p>Proveedor confiable</p>
              </div>
              <div className="why-us__individual">
                <div>
                  <img src={inventoryIconUrl} alt="Inventario de producto" />
                </div>
                <p>Inventario de producto</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="industrial-sectors">
        <Container>
          <h5 className="wow fadeInDown">sectores industriales</h5>
          <div
            className="industrial-sectors__grid wow fadeInUp"
            data-wow-delay=".7s"
          >
            <div className="industrial-sectors__grid__container">
              <div className="industrial-sectors__grid--auto">
                <div>
                  <img src={autoIconUrl} alt="Sector Automotriz" />
                </div>
                <p>automotriz</p>
              </div>
              <div className="industrial-sectors__grid--aero">
                <div>
                  <img src={planeIconUrl} alt="Sector Aeroespacial" />
                </div>
                <p>aeroespacial</p>
              </div>
              <div className="industrial-sectors__grid--build">
                <div>
                  <img src={buildingIconUrl} alt="Sector Construcción" />
                </div>
                <p>construcción</p>
              </div>
              <div className="industrial-sectors__grid--food">
                <div>
                  <img src={kitchenIconUrl} alt="Sector Alimenticio" />
                </div>
                <p>alimenticio</p>
              </div>
            </div>
            <div className="industrial-sectors__grid__container">
              <div className="industrial-sectors__grid--transport">
                <div>
                  <img src={transportIconUrl} alt="Sector del Transporte" />
                </div>
                <p>transporte</p>
              </div>
              <div className="industrial-sectors__grid--home">
                <div>
                  <img src={homeIconUrl} alt="Sector Home Appliance" />
                </div>
                <p>home appliance</p>
              </div>
              <div className="industrial-sectors__grid--furniture">
                <div>
                  <img src={furnitureIconUrl} alt="Muebles" />
                </div>
                <p>muebles</p>
              </div>
              <div className="industrial-sectors__grid--electro">
                <div>
                  <img src={homeElectroIconUrl} alt="Electrodoméstico" />
                </div>
                <p>electrodomésticos</p>
              </div>
            </div>
            <div className="industrial-sectors__grid__container">
              <div className="industrial-sectors__grid--gob">
                <div>
                  <img src={oilIconUrl} alt="Sector Automotriz" />
                </div>
                <p>gobierno</p>
              </div>
              <div className="industrial-sectors__grid--minery">
                <div>
                  <img src={mineyIconUrl} alt="Sector Aeroespacial" />
                </div>
                <p>minería</p>
              </div>
              <div className="industrial-sectors__grid--gas">
                <div>
                  <img src={gasIconUrl} alt="Sector Construcción" />
                </div>
                <p>oil and gas</p>
              </div>
              <div className="industrial-sectors__grid--electric">
                <div>
                  <img src={electricIconUrl} alt="Sector Alimenticio" />
                </div>
                <p>eléctrico</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Contact />
      {/* <section className="contact">
        <Container>
          <h2>solicita tu cotización</h2>
          <div
            className="contact__form__container"
            style={{ backgroundColor: "#fff" }}
          >
            <Form>
              <Form.Group controlId="formGroupName">
                <Form.Label>Nombre completo</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control type="email" />
              </Form.Group>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control type="number" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>¿Donde te encuentras?</Form.Label>
                <Form.Control as="select">
                  <option>Seleccionar estado...</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupProduct">
                <Form.Label>¿Qué producto buscas?</Form.Label>
                <Form.Control as="select">
                  <option>Seleccionar uno...</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Información adicional de su cotización</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
              <button className="form-send">Enviar</button>
            </Form>
          </div>
        </Container>
      </section> */}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={popUp} alt="Prominox" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
export default Home;
