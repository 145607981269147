import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

// Components
import Articles from "../../components/Articles";
import { CategoryJumbotron } from "../../components/Jumbotron/Jumbotron";
import Query from "../../components/Query";
import Contact from "../../components/Contact/Contact";

// Custom styles
import "./category.styles.scss";

// Queries
import CATEGORY_ARTICLES_QUERY from "../../queries/category/articles";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Media Assets
import benefit1IconUrl from "../../assets/Group.jpg";
import B11 from "../../assets/B11.jpg";
import B10 from "../../assets/B10.jpg";
import B13 from "../../assets/B13.png";
import B14 from "../../assets/B14.png";
import B15 from "../../assets/B15.png";
import TuboConduit from "../../assets/tubo-conduit-blogv2.png";

const NewCategory = () => {
  let { id } = useParams();
  return (
    <Query query={CATEGORY_ARTICLES_QUERY} id={id}>
      {({ data: { categories } }) => {
        console.log(categories);
        return (
          <div>
            <div className="category--v2">
              <div className="category--v2__articles">
                <Container>
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="primary-article"
                      style={{
                        backgroundImage: `url(${benefit1IconUrl})`,
                      }}
                    >
                      <div className="primary-article__content">
                        <h1>¿Dónde se usa el tubo conduit?</h1>
                        <p>
                          El tubo conduit se utiliza principalmente en
                          instalaciones eléctricas para proteger y enmascarar
                          los cables y conductores eléctricos.
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Row className="mt-2">
                    <Col xs="6" className="pr-0">
                      <Link to="/blogv2/articulo/acero-inoxidable">
                        <div
                          className="secondary-article"
                          style={{
                            backgroundImage: `url(${B11})`,
                          }}
                        >
                          <div className="secondary-article__heading">
                            <h3>¿Cómo se obtiene el aluminio?</h3>
                          </div>
                        </div>
                      </Link>
                    </Col>
                    <Col xs="6">
                      <Link to="/blogv2/articulo/acero-inoxidable">
                        <div
                          className="secondary-article"
                          style={{
                            backgroundImage: `url(${B10})`,
                          }}
                        >
                          <div className="secondary-article__heading">
                            <h3>¿Cómo se limpia el acero inoxidable?</h3>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="category--v2__secondary-articles">
                <h5>
                  Otros artículos: <span>(desliza hacia abajo)</span>
                </h5>
                <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B13})`,
                      }}
                    ></div>
                    <h4>Acero inoxidable en los hospitales</h4>
                  </Link>
                </div>
                <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B14})`,
                      }}
                    ></div>
                    <h4>¿Para qué se utiliza el alumnio en los camiones?</h4>
                  </Link>
                </div>
                <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B15})`,
                      }}
                    ></div>
                    <h4>Acero inoxidable y "la arquitectura moderna"</h4>
                  </Link>
                </div>
                <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B15})`,
                      }}
                    ></div>
                    <h4>Acero inoxidable y "la arquitectura moderna"</h4>
                  </Link>
                </div>
                {/* <div className="category--v2__slider">
                  <img src={TuboConduit} alt="" />
                </div> */}
              </div>
            </div>
            <Contact />
          </div>
        );
      }}
    </Query>
  );
};

export default NewCategory;
