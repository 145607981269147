import gql from "graphql-tag";

const ARTICLE_SLUG_QUERY = gql`
  query Articles($slug: ID!) {
    articles(where: { slug: $slug }) {
      id
      title
      content
      imagen {
        url
      }
      category {
        id
        name
        articles {
          id
          title
          content
          descripcion
          imagen {
            url
          }
          category {
            id
            name
          }
        }
      }
      published_at
    }
  }
`;

export default ARTICLE_SLUG_QUERY;
