import React from "react";

// Helmet
import Helmet from "react-helmet";

// Bootstrap
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";

// Custom styles
import "./landing.styles.scss";

// Media
import VideoIntro from "../../assets/videos/video_intro.mp4";
import prominoxLogoUrl from "../../assets/logo-prominox--white.png";
import gimLogo8Url from "../../assets/logo-gim-8.png";
import logoPeasa2 from "../../assets/logo-peasa2-8.png";
import logoPeasa8 from "../../assets/logo-peasa-8.png";
import logoProminox from "../../assets/logo-prominox.svg";
import logoGimtrac from "../../assets/logo-gimtrac-8.png";
import logoIntertek from "../../assets/Recurso-5-8.png";

// Media benefits
import benefit1IconUrl from "../../assets/icn-home6.png";
import benefit2IconUrl from "../../assets/icn-home8.png";
import benefit3IconUrl from "../../assets/icn-home1.png";
import benefit4IconUrl from "../../assets/icn-home3.png";
import benefit5IconUrl from "../../assets/icn-home4.png";
import benefit6IconUrl from "../../assets/icn-home5.png";
import benefit7IconUrl from "../../assets/icn-home7.png";
import benefit8IconUrl from "../../assets/icn-home2.png";

// Barandal icon
import barandalIconUrl from "../../assets/barandal-icon--white.png";

// Products
import bridasImgUrl from "../../assets/barandal-bridas.jpg";
import jaladerasImgUrl from "../../assets/barandal-jaladeras.jpg";
import conectoresImgUrl from "../../assets/barandal-conectores.jpg";
import soportesImgUrl from "../../assets/barandal-soportes.jpg";
import tapasImgUrl from "../../assets/barandal-tapas.jpg";
import ledImgUrl from "../../assets/barandal-led.jpg";
import Contact from "../../components/Contact/Contact";
import firstSlideImgUrl from "../../assets/accesorios-barandales-slide-1.png";
import secondSlideImgUrl from "../../assets/accesorios-barandales-slide-2.png";
import thirdSlideImgUrl from "../../assets/accesorios-barandales-slide-3.png";
import fourthSlideImgUrl from "../../assets/accesorios-barandales-slide-4.jpg";

const benefits = [
  {
    id: 1,
    image: benefit1IconUrl,
    text: "Requerimientos especiales",
  },
  {
    id: 2,
    image: benefit5IconUrl,
    text: "Atención personalizada",
  },
  {
    id: 3,
    image: benefit2IconUrl,
    text: "Entrega a tiempo",
  },
  {
    id: 4,
    image: benefit6IconUrl,
    text: "Proveedor confiable",
  },
  {
    id: 5,
    image: benefit3IconUrl,
    text: "Producto a la medida",
  },
  {
    id: 6,
    image: benefit7IconUrl,
    text: "Inventario de producto",
  },
  {
    id: 7,
    image: benefit4IconUrl,
    text: "Calidad del producto",
  },
  {
    id: 8,
    image: benefit8IconUrl,
    text: "Cobertura de distribución nacional",
  },
];

export default function Herrajes() {
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Prominox | Herrajes</title>
        <meta
          name="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta
          name="keywords"
          content="Herrajes, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Prominox | Herrajes" />
        <meta
          itemprop="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prominox | Herrajes" />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Prominox | Herrajes" />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-landing">
          <video
            autoPlay
            loop
            muted
            className="video"
            // style={{ objectFit: "cover" }}
          >
            <source src={VideoIntro} type="video/mp4" />
          </video>
          <Container>
            <Row>
              <Col xs={12} lg={6}>
                <img src={prominoxLogoUrl} alt="Prominox " />
              </Col>
              <Col xs={12} lg={6}>
                <h1 className="wow fadeInUp">ACCESORIOS PARA BARANDALES</h1>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </section>
      <section className="about">
        <Container fluid>
          <Row>
            <Col xs={12} lg={6} className="wow fadeInUp">
              <div className="about__heading">
                <div>
                  <h1>Somos</h1>
                  <h2>Grupo</h2>
                  <h3>Industrial</h3>
                  <h4>Mexicano</h4>
                </div>
                <img src={gimLogo8Url} alt="Prominox" />
              </div>
              <Row className="about__group">
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center"
                >
                  <img src={logoPeasa2} alt="Prominox" />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center"
                >
                  <img src={logoPeasa8} alt="Prominox" />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center mt-3 mt-md-0"
                >
                  <img
                    src={logoProminox}
                    style={{ height: "80%" }}
                    alt="Prominox"
                  />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center mt-3 mt-md-0"
                >
                  <img src={logoGimtrac} alt="Prominox" />
                </Col>
              </Row>
              <Row className="about__certifications">
                <Col xs={6} lg={4}>
                  <img src={logoIntertek} alt="Prominox" />
                </Col>
                <Col xs={6} lg={8} className="d-flex align-items-md-end">
                  <h1>NUESTRAS CERTIFICACIONES</h1>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="about__benefits wow fadeInDown"
              data-wow-delay=".3s"
            >
              <h2>¿Por qué comprar en Prominox?</h2>
              <Row>
                {benefits.map((benefit) => (
                  <Col
                    xs={12}
                    md={6}
                    className="d-flex align-items-center mt-3"
                  >
                    <img src={benefit.image} alt="" />
                    <p>{benefit.text}</p>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="jumbotron--landing">
        <h1 className="wow fadeInUp">accesorios para barandales</h1>
        <div
          className="jumbotron--landing__list  wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div>
            <img src={barandalIconUrl} alt="Prominox" />
            <h3>Fácil instalación</h3>
          </div>
          <div>
            <img src={barandalIconUrl} alt="Prominox" />
            <h3>Gran durabilidad</h3>
          </div>
          <div>
            <img src={barandalIconUrl} alt="Prominox" />
            <h3>Elegantes</h3>
          </div>
          <div>
            <img src={barandalIconUrl} alt="Prominox" />
            <h3>Seguridad</h3>
          </div>
          <div>
            <img src={barandalIconUrl} alt="Prominox" />
            <h3>Fácil mantenimiento</h3>
          </div>
        </div>
      </section>
      <section className="accesories-lineup">
        <Container>
          <h2 className="wow fadeInUp">línea de productos</h2>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={4}>
              <img src={bridasImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4}>
              <img src={jaladerasImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4}>
              <img src={conectoresImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4} className="mt-md-4">
              <img src={soportesImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4} className="mt-md-4">
              <img src={tapasImgUrl} alt="Accesorios para barandales" />
            </Col>
            <Col xs={6} md={4} className="mt-md-4">
              <img src={ledImgUrl} alt="Accesorios para barandales" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="catalogue">
        <Container>
          <h3 className="wow fadeInUp">catálogo</h3>
          <iframe
            title="e-magazine"
            src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=f7hnannh6"
            width="100%"
            height="480"
            seamless="seamless"
            scrolling="no"
            frameBorder="0"
            allowFullScreen
            className="wow fadeInUp"
          ></iframe>
        </Container>
      </section>
      <section className="carousel--accesories-container">
        <div className="carousel--accesories-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>
        <div
          className="carousel--accesories-caption--bottom wow fadeInUp"
          data-wow-delay=".3s"
        >
          <p>Aplicacion en casa-habitación.</p>
        </div>
        <Carousel interval={null} className="mb-0">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={firstSlideImgUrl}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={secondSlideImgUrl}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={thirdSlideImgUrl}
              alt="Third slide"
            />
            {/* <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={fourthSlideImgUrl}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
      </section>
      <Contact />
    </div>
  );
}
