import React from "react";

// Helmet
import Helmet from "react-helmet";

// Components
import Contact from "../../components/Contact/Contact";
import { BranchCard } from "../../components/CustomCards/CustomCards";

// Bootstrap
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import CardDeck from "react-bootstrap/CardDeck";

// Custom styles
import "./landing.styles.scss";

// Media
import VideoIntro from "../../assets/videos/video_intro.mp4";
import prominoxLogoUrl from "../../assets/logo-prominox--white.png";
import gimLogo8Url from "../../assets/logo-gim-8.png";
import logoPeasa2 from "../../assets/logo-peasa2-8.png";
import logoPeasa8 from "../../assets/logo-peasa-8.png";
import logoProminox from "../../assets/logo-prominox.svg";
import logoGimtrac from "../../assets/logo-gimtrac-8.png";
import logoIntertek from "../../assets/Recurso-5-8.png";

import firstIconUrl from "../../assets/icn-03.png";
import thirdIconUrl from "../../assets/icn-02.png";
import fourthIconUrl from "../../assets/icn-06.png";
import eigthIconUrl from "../../assets/icn-07.png";
import ninethIconUrl from "../../assets/icn-01.png";
import thirteenIconUrl from "../../assets/icn-05.png";
import fourthteenIconUrl from "../../assets/icn-04.png";

// Media benefits
import benefit1IconUrl from "../../assets/icn-home6.png";
import benefit2IconUrl from "../../assets/icn-home8.png";
import benefit3IconUrl from "../../assets/icn-home1.png";
import benefit4IconUrl from "../../assets/icn-home3.png";
import benefit5IconUrl from "../../assets/icn-home4.png";
import benefit6IconUrl from "../../assets/icn-home5.png";
import benefit7IconUrl from "../../assets/icn-home7.png";
import benefit8IconUrl from "../../assets/icn-home2.png";

// Barandal icon
import steelIconUrl from "../../assets/icn-acero-lan-8.png";

// Products
import tuberiaPipeImgUrl from "../../assets/acero-inoxidable-tuberia-pipe.jpg";
import soleraImgUrl from "../../assets/acero-inoxidable-solera.jpg";
import rolloImgUrl from "../../assets/acero-inoxidable-rollo.jpg";
import placaImgUrl from "../../assets/acero-inoxidable-placa.jpg";
import ornamentalRedImgUrl from "../../assets/acero-inoxidable-ornamental-redonda.jpg";
import ornamentalRectImgUrl from "../../assets/acero-inoxidable-ornamental-rectangular.jpg";
import ornamentalCuadImgUrl from "../../assets/acero-inoxidable-ornamental-cuadrada.jpg";
import laminaImgUrl from "../../assets/acero-inoxidable-lamina.jpg";
import flejeImgUrl from "../../assets/acero-inoxidable-fleje.jpg";
import discoImgUrl from "../../assets/acero-inoxidable-disco.jpg";
import blankImgUrl from "../../assets/acero-inoxidable-blank.jpg";
import barraRedImgUrl from "../../assets/acero-inoxidable-barra-redonda.jpg";
import barraCuadImgUrl from "../../assets/acero-inoxidable-barra-cuadrada.jpg";
import antiDerraImgUrl from "../../assets/acero-inoxidable-antiderrapante.jpg";
import anguloImgUrl from "../../assets/acero-inoxidable-angulo.jpg";

// Branches media
import agsImgUrl from "../../assets/ags.jpg";
import chihImgUrl from "../../assets/img-chihuahua.jpg";
import clcImgUrl from "../../assets/img-culiacan-b.jpg";
import gdlImgUrl from "../../assets/img-guadalajara.jpg";
import iztaImgUrl from "../../assets/img-iztapalapa-b.jpg";
import leonImgUrl from "../../assets/img-leon.jpg";
import mazatImgUrl from "../../assets/img-mazatlan.jpg";
import montImgUrl from "../../assets/img-monterrey.jpg";
import moreImgUrl from "../../assets/img-morelia.jpg";
import pueImgUrl from "../../assets/img-puebla.jpg";
import quereImgUrl from "../../assets/img-queretaro-b.jpg";
import reynImgUrl from "../../assets/img-reynosa-2.jpg";
// import saltiImgUrl from "../../assets/img-saltillo.jpg";
import slpImgUrl from "../../assets/sanluis.jpg";
import tijImgUrl from "../../assets/img-tijuana.jpg";
import tlalnImgUrl from "../../assets/mexico.jpg";
import torrnImgUrl from "../../assets/img-torreon.jpg";
import vcrzImgUrl from "../../assets/img-veracruz.jpg";
import tmpSecondImgUrl from "../../assets/tampico-second.jpg";

const benefits = [
  {
    id: 1,
    image: benefit1IconUrl,
    text: "Requerimientos especiales",
  },
  {
    id: 2,
    image: benefit5IconUrl,
    text: "Atención personalizada",
  },
  {
    id: 3,
    image: benefit2IconUrl,
    text: "Entrega a tiempo",
  },
  {
    id: 4,
    image: benefit6IconUrl,
    text: "Proveedor confiable",
  },
  {
    id: 5,
    image: benefit3IconUrl,
    text: "Producto a la medida",
  },
  {
    id: 6,
    image: benefit7IconUrl,
    text: "Inventario de producto",
  },
  {
    id: 7,
    image: benefit4IconUrl,
    text: "Calidad del producto",
  },
  {
    id: 8,
    image: benefit8IconUrl,
    text: "Cobertura de distribución nacional",
  },
];
const branchesArray = [
  {
    imgUrl: agsImgUrl,
    cityName: "Aguascalientes",
    branchPhone: "4491464235",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Carretera a San Luis Potosí km 2.0, Fracc. Casa Sólida, Aguascalientes, Aguascalientes. C.P. 20169",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox+Aguascalientes/@21.861506,-102.225083,17z/data=!4m5!3m4!1s0x0:0x387290c2ade98517!8m2!3d21.8615063!4d-102.2250835?hl=es-419",
  },
  {
    imgUrl: chihImgUrl,
    cityName: "Chihuahua",
    branchPhone: "6143070246",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Alejandro Dumas No. 11130, No .Int 10 y 11, Complejo Industrial Chihuahua, Chihuahua, Chihuahua, C.P. 31136",
    branchLocationLink:
      "https://www.google.com/maps?ll=28.729956,-106.11388&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=2030636383931288465",
  },
  {
    imgUrl: clcImgUrl,
    cityName: "Culiacán",
    branchPhone: "6671705198",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Libramiento Sur Benito Juárez No. 6301, Int. 34ª Poniente, Col. Parque Industrial la costa, Culiacán Rosales, Culiacán, Sinaloa, C.P. 80140",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox/@24.748713,-107.457904,17z/data=!4m5!3m4!1s0x0:0xb43a5ee1ce281ebd!8m2!3d24.7487126!4d-107.457904?hl=es-419",
  },
  {
    imgUrl: gdlImgUrl,
    cityName: "Guadalajara",
    branchPhone: "3333444636",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Patria No. 29, Col. El Manantial, Guadalajara, Jalisco, C.P. 44990",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.622249,-103.345902&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=17318830583385505820",
  },
  {
    imgUrl: iztaImgUrl,
    cityName: "Iztapalapa",
    branchPhone: "5555815896",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. 5 de Mayo No. 9, Local E, Colonia Ampliación los Reyes Culhuacán, Delegación Iztapalapa, C.P. 09849,Ciudad de México, México",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.343021,-99.105949&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=1610868234904062615",
  },
  {
    imgUrl: leonImgUrl,
    cityName: "León",
    branchPhone: "4777776405",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Blvd. Alonso de Torres, No. 231-A, Col. Las Hilamas, León, Guanajuato, C.P. 37353",
    branchLocationLink:
      "https://www.google.com/maps?ll=21.123541,-101.720131&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3260945479459544977",
  },
  {
    imgUrl: mazatImgUrl,
    cityName: "Mazatlán",
    branchPhone: "6691332595",
    branchSecondaryPhone: "6691332592",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Francisco Gonzalez Bocanegra No. 8900, Col. El Conchi II, Mazatlán, Sinaloa,C.P. 82134",
    branchLocationLink:
      "https://www.google.com/maps?ll=23.272148,-106.40246&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=12985841781860867956",
  },
  {
    imgUrl: montImgUrl,
    cityName: "Mérida",
    branchPhone: "9992522233",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Periférico poniente KM 41+700, No. 3, Fraccionamiento Juan Pablo II, Mérida, Yucatán, C.P. 97246",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.982791,-89.680539&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=14570237733386454061",
  },
  {
    imgUrl: montImgUrl,
    cityName: "Monterrey",
    branchPhone: "8181440010",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "América del Norte 208, Col. Parque Industrial Las Américas",
    branchLocationLink:
      "https://www.google.com/maps?ll=25.699747,-100.241753&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=3198052743347169609",
  },
  {
    imgUrl: moreImgUrl,
    cityName: "Morelia",
    branchPhone: "4433230424",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "General Jose Aceves, No. 36, Col. Elías Pérez Avalos, Morelia, Michoacán de Ocampo,C.P. 58218",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.711324,-101.165418&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=5907667291632038982",
  },
  {
    imgUrl: pueImgUrl,
    cityName: "Puebla",
    branchPhone: "2222330000",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Calle: Río Lerma No. 10 Bodega 3, Colonia: San Lorenzo Almecatla, Cuautlancingo, Puebla, C.P.72730",
    branchLocationLink:
      "https://www.google.com/maps?ll=19.118929,-98.257698&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=8138320865941269881",
  },
  {
    imgUrl: quereImgUrl,
    cityName: "Querétaro",
    branchPhone: "4422145914",
    branchSecondaryPhone: "4422175191",
    branchThirdPhone: "4422177766",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Camino a Campo Militar No. 25 (KM 2.65), Nave A-4, Col. La Sierrita, Santiago de Querétaro, Querétaro, Querétaro, CP. 76137",
    branchLocationLink:
      "https://www.google.com/maps?ll=20.594751,-100.418403&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=4052025410070731133",
  },
  // {
  //   imgUrl: reynImgUrl,
  //   cityName: "Reynosa",
  //   branchPhone: "8999256505",
  //   branchSchedule:
  //     "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
  //   branchLocation:
  //     "Av. Rodolfo Garza Cantú, No. 102, Col. Las Mitras, Reynosa, Tamaulipas, C.P. 88759",
  //   branchLocationLink:
  //     "https://www.google.com/maps?ll=26.04706,-98.300278&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15870023934054905851",
  // },
  // {
  //   imgUrl: saltiImgUrl,
  //   cityName: "Saltillo",
  //   branchPhone: "8442779576",
  //   branchSchedule:
  //     "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
  //   branchLocation:
  //     "Blvd. Vito Alessio Robles 5625, Asturias. Saltillo, Coahuila, C.P. 25107",
  //   branchLocationLink:
  //     "https://www.google.com/maps/place/Prominox/@25.478969,-100.991305,17z/data=!4m5!3m4!1s0x0:0x5bcb75062832147a!8m2!3d25.4789685!4d-100.9913047?hl=es-419",
  // },
  {
    imgUrl: slpImgUrl,
    cityName: "San Luis Potosí",
    branchPhone: "4448161007",
    branchSecondaryPhone: "4448096454",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Carretera Rio Verde No. 2260, Col. Genovevo Rivas Guillen Gral.,Soledad de Graciano Sanchez, San Luis Potosí, C.P. 78436",
    branchLocationLink:
      "https://www.google.com/maps?ll=22.1533,-100.935828&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=10168027290383277582",
  },
  {
    imgUrl: reynImgUrl,
    cityName: "Tampico",
    branchPhone: "8331000142",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "6A Avenida 317, Villa Hermosa, Tampico, Tamaulipas C.P. 89319 ",
    branchLocationLink:
      "https://www.google.com/maps?ll=22.323001,-97.86955&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15835830103075095847",
    secondImgUrl: tmpSecondImgUrl,
  },
  {
    imgUrl: tijImgUrl,
    cityName: "Tijuana",
    branchPhone: "6649695548",
    branchSecondaryPhone: "6649695576",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Vía rápida oriente, No. 15540, Rio Tijuana 3ra Etapa, Tijuana, Baja California, C.P. 22226",
    branchLocationLink:
      "https://www.google.com/maps?ll=32.496169,-116.941583&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16608062537878815893",
  },
  {
    imgUrl: tlalnImgUrl,
    cityName: "Tlalnepantla",
    branchPhone: "5550050580",
    branchSchedule: "Lunes a Viernes 8:30 am a 6:00 pm. ",
    branchLocation:
      "Henry Ford No. 4, No. Int. 3, Col. Industrial San Nicolas, Tlalnepantla, Edo de México, C.P. 54030",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox/@19.552802,-99.204649,17z/data=!4m5!3m4!1s0x0:0x3071f6b979be0655!8m2!3d19.5528017!4d-99.2046493?hl=es-419",
  },
  {
    imgUrl: torrnImgUrl,
    cityName: "Torreón",
    branchPhone: "8717187836",
    branchSecondaryPhone: "8717187293",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Blvd. Torreón Matamoros, No. 7691 Ote, No. Int. Manzana 57, Lote 8-C, Col. Oscar Flores Tapia, Torreón, Coahuila de Zaragoza, C.P. 27086",
    branchLocationLink:
      "https://www.google.com/maps?ll=25.535083,-103.34414&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16628314242009464813",
  },
  {
    imgUrl: vcrzImgUrl,
    cityName: "Veracruz",
    branchPhone: "2299208405",
    branchSchedule:
      "Lunes a Viernes 8:30 am a 6:00 pm. Sábado 9:00 am a 1:00 pm",
    branchLocation:
      "Av. Sin nombre #94 A un costado de Velatorio la Luz Frente a la Laguna Malibrán, Floresta, 91940 Veracruz, Ver. C.P. 91940",
    branchLocationLink:
      "https://www.google.com/maps/place/Prominox+Veracruz+%7C+Acero+Inoxidable+%7C+Aluminio+%7C+Tuber%C3%ADa+Conduit/@19.1616498,-96.1385608,17z/data=!3m1!4b1!4m5!3m4!1s0x85c343522405ab89:0x87c23a38ef8c9132!8m2!3d19.1616633!4d-96.1363799",
  },
];

export default function AceroInoxidable() {
  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>Acero Inoxidable ADS - Prominox</title>
        <meta
          name="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta
          name="keywords"
          content="Herrajes, herrajes, acero inoxidable, bridas, jaladeras, soportes, tapas, conectores, led line"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Acero Inoxidable ADS - Prominox" />
        <meta
          itemprop="description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta itemprop="image" content="" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content="https://testing-server-3840d.web.app"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Acero Inoxidable ADS - Prominox" />
        <meta
          property="og:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta property="og:image" content="" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Acero Inoxidable ADS - Prominox" />
        <meta
          name="twitter:description"
          content="Contamos con herrajes para barandales en sus múltiples líneas de productos como conectores, ridas, soportes, tapas, led linea y jaladeras"
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <section className="hero">
        <Jumbotron fluid className="jumbotron--hero-landing">
          <video
            autoPlay
            loop
            muted
            className="video"
            // style={{ objectFit: "cover" }}
          >
            <source src={VideoIntro} type="video/mp4" />
          </video>
          <Container>
            <Row>
              <Col xs={12} lg={6}>
                <img src={prominoxLogoUrl} alt="Prominox " />
              </Col>
              <Col xs={12} lg={6}>
                <h1 className="wow fadeInDown">acero inoxidable</h1>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </section>
      <section className="about">
        <Container fluid>
          <Row>
            <Col xs={12} lg={6} className="wow fadeInDown">
              <div className="about__heading">
                <div>
                  <h1>Somos</h1>
                  <h2>Grupo</h2>
                  <h3>Industrial</h3>
                  <h4>Mexicano</h4>
                </div>
                <img src={gimLogo8Url} alt="Prominox" />
              </div>
              <Row className="about__group">
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center"
                >
                  <img src={logoPeasa2} alt="Prominox" />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center"
                >
                  <img src={logoPeasa8} alt="Prominox" />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center justify-content-lg-center mt-3 mt-md-0"
                >
                  <img
                    src={logoProminox}
                    style={{ height: "80%" }}
                    alt="Prominox"
                  />
                </Col>
                <Col
                  xs={6}
                  lg={3}
                  className="d-flex align-items-center mt-3 mt-md-0"
                >
                  <img src={logoGimtrac} alt="Prominox" />
                </Col>
              </Row>
              <Row className="about__certifications">
                <Col xs={6} lg={4}>
                  <img src={logoIntertek} alt="Prominox" />
                </Col>
                <Col xs={6} lg={8} className="d-flex align-items-md-end">
                  <h1>NUESTRAS CERTIFICACIONES</h1>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="about__benefits wow fadeInUp"
              data-wow-delay=".3s"
            >
              <h2>¿Por qué comprar en Prominox?</h2>
              <Row>
                {benefits.map((benefit) => (
                  <Col
                    xs={12}
                    md={6}
                    className="d-flex align-items-center mt-3"
                  >
                    <img src={benefit.image} alt="" />
                    <p>{benefit.text}</p>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="jumbotron--landing--acero">
        <h1 className="wow fadeInUp">acero inoxidable</h1>
        <div
          className="jumbotron--landing--acero__list wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div>
            <img src={steelIconUrl} alt="Prominox" />
            <h3>Resistencia a la corrosión</h3>
          </div>
          <div>
            <img src={steelIconUrl} alt="Prominox" />
            <h3>Durabilidad</h3>
          </div>
          <div>
            <img src={steelIconUrl} alt="Prominox" />
            <h3>Resistencia mecánica</h3>
          </div>
          <div>
            <img src={steelIconUrl} alt="Prominox" />
            <h3>Apariencia higiénica</h3>
          </div>
          <div>
            <img src={steelIconUrl} alt="Prominox" />
            <h3>Excelente soldabilidad</h3>
          </div>
          <div>
            <img src={steelIconUrl} alt="Prominox" />
            <h3>Resistencia a temperaturas extremas</h3>
          </div>
        </div>
      </section>
      <section className="accesories-lineup">
        <Container>
          <h2 className="wow fadeInUp">línea de productos</h2>
          <Row className="wow fadeInUp" data-wow-delay=".3s">
            <Col xs={6} md={3}>
              <img src={tuberiaPipeImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3}>
              <img src={soleraImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3}>
              <img src={rolloImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3}>
              <img src={placaImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={ornamentalRedImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={ornamentalRectImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={ornamentalCuadImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={laminaImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={flejeImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={discoImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={blankImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={barraRedImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={barraCuadImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={antiDerraImgUrl} alt="Acero Inoxidable" />
            </Col>
            <Col xs={6} md={3} className="mt-md-4">
              <img src={anguloImgUrl} alt="Acero Inoxidable" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="jumbotron--landing--acero-inoxidable">
        <Container fluid className="h-100">
          <Row className="h-100">
            <Col xs={12} lg={6} className="wow fadeInUp">
              <div className="card">
                <h1>AUSTENÍTICOS</h1>
                <h3>aleaciones comunes</h3>
                <div className="d-flex justify-content-center">
                  <h4>201</h4>
                  <h4>301</h4>
                  <h4>304</h4>
                  <h4>310</h4>
                  <h4>316</h4>
                </div>
                <h5>propiedades</h5>
                <Row>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={fourthIconUrl} alt="PROMINOX" />
                      <p>Excelente soldabilidad</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={thirteenIconUrl} alt="PROMINOX" />
                      <p>Gran higiene y limpieza</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={fourthteenIconUrl} alt="PROMINOX" />
                      <p>Fácil dilatación y contracción</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} lg={6} className="wow fadeInUp" data-wow-delay=".3s">
              <div className="card mt-3 mt-lg-0">
                <h1>FERRÍTICOS</h1>
                <h3>aleaciones comunes</h3>
                <div className="d-flex justify-content-center">
                  <h4>409 L</h4>
                  <h4>430</h4>
                  <h4>441</h4>
                </div>
                <h5>propiedades</h5>
                <Row>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={eigthIconUrl} alt="PROMINOX" />
                      <p>Excelente resistencia a la corrosión</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={firstIconUrl} alt="PROMINOX" />
                      <p>Magnéticos</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={thirdIconUrl} alt="PROMINOX" />
                      <p>Buena formabilidad</p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="jumbotron--landing--acero-inoxidable__item">
                      <img src={ninethIconUrl} alt="PROMINOX" />
                      <p>Difusión eficiente de calor</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="branches">
        <Container>
          <h1 className="wow fadeInUp">20 sucursales en méxico</h1>
          <CardDeck className="wow fadeInUp" data-wow-delay=".3s">
            {branchesArray.map(
              ({
                imgUrl,
                cityName,
                branchPhone,
                branchSecondaryPhone,
                branchSchedule,
                branchLocation,
                branchLocationLink,
                secondImgUrl,
              }) => (
                <BranchCard
                  imgUrl={imgUrl}
                  cityName={cityName}
                  branchPhone={branchPhone}
                  branchSecondaryPhone={branchSecondaryPhone}
                  branchSchedule={branchSchedule}
                  branchLocation={branchLocation}
                  branchLocationLink={branchLocationLink}
                  secondImgUrl={secondImgUrl}
                />
              )
            )}
          </CardDeck>
        </Container>
      </section>
      <section className="carousel--steel__applications-container">
        <div className="carousel-caption--top">
          <h4 className="wow fadeInUp">Aplicaciones</h4>
        </div>

        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--1">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                <span>Cocinas industriales</span>
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--2">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                equipo de refinación petróleo
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--3">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                adornos arquitectónicos
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 409 L, 430 y 441</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>resistencia a la corrosión</li>
                    <li>magnéticos</li>
                    <li>buena formabilidad</li>
                    <li>difusión eficiente de calor</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel--steel__applications__item carousel--steel__applications__item--4">
              <h2 className="wow fadeInUp" data-wow-delay=".3s">
                equipos de laboratorio
              </h2>
              <div
                className="carousel-caption--bottom wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div>
                  <p>aleaciones comunes: 201, 301, 304, 310 y 316</p>
                </div>
                <div>
                  <p>propiedades</p>
                  <ul>
                    <li>excelente resistencia a la corrosión</li>
                    <li>excelente soldabilidad</li>
                    <li>gran higiene y limpieza</li>
                    <li>fácil dilatación y contracción</li>
                  </ul>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      <Contact />
    </div>
  );
}
